import { AVATAR_URL, ORGANISATION_OWNER } from "common/constants";
import { map } from "lodash";
import React from "react";
import Editable from "react-bootstrap-editable";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import {
  defaultRoleOptions,
  defaultAccessOptions,
  invitedUserAccessOptions,
} from "../jobCreation/utils/user";
export const createOption = label => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const participantsAvatar = {
  height: 56,
  width: 56,
  backgroundColor: "#f6f6f6",
  padding: 3,
};

const UserCards = ({ users, onRemove, onRoleChange, onAccessChange, participantWorkDates }) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const getRoleOptions = () => {
    return defaultRoleOptions.map(role => role.label);
  };
  const getAccessOptions = invited => {
    return invited
      ? invitedUserAccessOptions.map(role => role.label)
      : defaultAccessOptions.map(role => role.label);
  };

  return (
    <Row>
      {map(users, (
        user, key) => {
        console.log('username', user)
        return (
          <Col xl="4" sm="6" key={key}>
            <Card>
              <CardBody>
                <img
                  style={participantsAvatar}
                  className="rounded-circle header-profile-user"
                  src={
                    user.photoURL
                      ? user.photoURL
                      : `${AVATAR_URL}&name=${user.firstName && user.lastName
                        ? `${user.firstName[0]}${user.lastName[0]}`
                        : user.displayName && user.displayName
                      }`
                  }
                  alt="Avatar"
                />
                <span className="text-end">
                  <button
                    disabled={
                      currentUserProfile.id === user.id ||
                      user.type === ORGANISATION_OWNER
                    }
                    className="btn btn-danger w-md ml-20"
                    onClick={() => {
                      onRemove(user);
                    }}
                  >
                    Remove
                  </button>
                </span>
                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-14">
                    {!user?.firstName && !user?.lastName ? user.displayName : `${user?.firstName} ${user?.lastName}`}
                  </h5>
                  <h5 className="text-truncate font-size-11">{user?.email}</h5>
                  <hr />
                  <h5 className="text-truncate font-size-12">
                    {"Access: "}
                    <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className={null}
                      disabled={user.type === ORGANISATION_OWNER}
                      editText="Edit"
                      id={null}
                      initialValue={user?.access?.label}
                      isValueClickable={!!user?.access?.label}
                      mode="inline"
                      onSubmit={value => {
                        if (value) {
                          onAccessChange(user.id, {
                            access: {
                              label: value,
                              value: value.toLowerCase().replace(/\W/g, ""),
                            },
                          });
                        }
                      }}
                      onValidated={null}
                      options={getAccessOptions(user.invited || false)}
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type="select"
                      validate={null}
                    />
                  </h5>
                  <h5 className="text-truncate font-size-12">
                    {"Role: "}
                    <Editable
                      ajax={null}
                      alwaysEditing={false}
                      className={null}
                      disabled={false}
                      editText="Edit"
                      id={null}
                      initialValue={user?.role?.label}
                      isValueClickable={!!user?.role?.label}
                      mode="inline"
                      onSubmit={value => {
                        if (value) {
                          onRoleChange(user.id, {
                            role: {
                              label: value,
                              value: value.toLowerCase().replace(/\W/g, ""),
                            },
                            hasRoleChange: true,
                            previousRole: user.role.label,
                          });
                        }
                      }}
                      onValidated={null}
                      options={getRoleOptions()}
                      renderCancelElement={null}
                      renderConfirmElement={null}
                      showText
                      type="select"
                      validate={null}
                    />
                  </h5>
                  {participantWorkDates(user)}
                </Media>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default UserCards;
