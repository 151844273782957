import React, { useEffect, useState } from "react";
import { Alert, Button, Input, Row ,Progress} from "reactstrap";
import "./Certificate.scss";
import "../../../jobCreation/checklist/addSection.scss";
import { useSelector } from "react-redux";
import "./ReportGeneration.scss";
import "../../../../../themes/RichTextEditorStyles.css";
import JobReportChecklist from "./JobReportChecklist";
import ClearAllConfirmationPrompt from "components/Common/DeleteConfirmationPrompt";
import TemplateSectionPosition from "./TemplateSectionPosition";
import {
  handleDeleteField,
  handleDescription,
  handleDescriptionChange,
  handleTitleChange,
  toggleDescription,
  updateSaveButtonStatus,
} from "./handleDynamicFieldsInTemplate";
import RichTextEditor from "../../rooms/RichTextEditor";
import { toast } from "react-toastify";
 import useProgressStore from "../../rooms/zustandService/progressStore";
const {  progressValue } = useProgressStore.getState();
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Alert, ProgressBar } from 'react-bootstrap';
const CreateTemplateModel = ({
  progress,
  loading,
  setLoading,
  setShowCertModal,
  jobReportTemplate,
  setJobReportTemplate,
  templateName,
  setTemplateName,
  setModalVisible,
  onDragEnd,
  onGenerate,
  myCheckLists,
  saveTemplate,
  isDirty,
  job,
  jobType,
  collectionName,
  clearJobReportTemplate,
  setDirty,
  floor,
  areas,
  setIsChecklistChanged
}) => {
  
  const { currentUserProfile } = useSelector(state => state.profile);

  const [isSectionToBePlaced, setIsSectionToBePlaced] = useState(false);
  const [includeChecklist, setIncludeChecklist] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [includeEsign, setIncludeEsign] = useState(false);
  const [deleteAllSectionsAlert, setDeleteAllSectionsAlert] = useState(false);
  const [sectionDeleteAlert, setSectionDeleteAlert] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);

  return (
    <>
      {sectionDeleteAlert && (
        <ClearAllConfirmationPrompt
          onCancel={() => {
            setSectionDeleteAlert(false);
            setDeleteIndex(null);
          }}
          onConfirm={() => {
            handleDeleteField(
              deleteIndex,
              setDirty,
              jobReportTemplate,
              setJobReportTemplate
            );
            setSectionDeleteAlert(false);
            setDeleteIndex(null);
            toast.success("section deleted successfully !", {
              autoClose: 2000,
            });
          }}
          title={`Are you sure you want to delete the section?`}
          confirmBtnText={"Yes, delete it."}
          cancelBtnText={"No, Keep it."}
        />
      )}
      {deleteAllSectionsAlert && (
        <ClearAllConfirmationPrompt
          onCancel={() => setDeleteAllSectionsAlert(false)}
          onConfirm={() => {
            clearJobReportTemplate();
            setDeleteAllSectionsAlert(false);
            toast.success("All sections deleted successfully !", {
              autoClose: 2000,
            });
          }}
          title={`Are you sure you want to clear all the Sections?`}
          confirmBtnText={"Yes, delete it."}
          cancelBtnText={"No, Keep it."}
        />
      )}

      <div style={{ backgroundColor: "white", padding: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            color="link"
            onClick={() => {
              setShowCertModal(false);
              setJobReportTemplate([]);
            }}
            disabled={loading || isSectionToBePlaced}
            style={{ transform: "translateY(-5%)" }}
          >
            <i
              className="bx bx-caret-left"
              style={{ transform: "translateY(4%)" }}
            ></i>
            Back
          </Button>
          <h5 className="modal-title mt-0" id="myModalLabel">
            {`Generate Report`}
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
            <p>
              <strong>Job No: </strong> {job?.jobNo}
            </p>
            <p>
              <strong>Job Address: </strong>
              {job?.jobAddress}
            </p>
            <p>
              <strong>Client Address: </strong>
              {job?.client?.address}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <JobReportChecklist
              myListItems={myCheckLists}
              onDragEnd={onDragEnd}
            />
            <Button
              disabled={isSectionToBePlaced}
              onClick={() => {
                setModalVisible(true);
                setDirty(true);
              }}
              style={{
                marginLeft: "20px",
                marginBottom: "10px",
                float: "right",
              }}
              color="primary"
            >
              Add Section
            </Button>
          </div>
        </div>
        <Row className="mt-2">
          <div className="mb-2">
            <div style={{ display: "flex", alignItems: "center" ,justifyContent: "space-between" }}>
            <h5 className="modal-title mt-0" id="myModalLabel">
            {`Template Name`}
          </h5>
           
            <Button
              onClick={() => {
                setDeleteAllSectionsAlert(true);
              }}
              color="danger"
              disabled={
                isSectionToBePlaced ||
                (jobReportTemplate?.items || jobReportTemplate)?.length === 0
              }
              style={{
                float: "right",
                marginLeft: "20px",
                marginBottom: "10px",
              }}
            >
              Clear All
            </Button>
            </div>
        
            <Input
              disabled={isSectionToBePlaced}
              type="text"
              value={templateName}
              placeholder="Enter Template Name"
              onChange={e => {
                setTemplateName(e.target.value);
                updateSaveButtonStatus(
                  jobReportTemplate?.items || jobReportTemplate,
                  setDirty
                );
              }}
            />
          </div>
          <div>
            <div className="scrollable-content-checklist">
              {(jobReportTemplate?.items || jobReportTemplate)?.map(
                (template, index) => (
                  <>
                    <div className="field">
                      <div
                        style={{
                          display: "flex",
                          margin: "10px",
                        }}
                      >
                        <div
                          className="field"
                          style={{ flexGrow: 8, margin: "10px" }}
                        >
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder={`Title`}
                              value={template?.name}
                              disabled={isSectionToBePlaced}
                              onChange={e =>
                                handleTitleChange(
                                  index,
                                  e.target.value,
                                  setDirty,
                                  jobReportTemplate,
                                  setJobReportTemplate
                                )
                              }
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginBottom: "10px",
                                border: "1px solid #cccc",
                                borderRadius: "5px",
                                boxSizing: "border-box",
                              }}
                              required
                            />
                          </div>
                          {!template?.showDescription &&
                            !template?.description && (
                              <button
                                color="primary"
                                style={
                                  isSectionToBePlaced ? { opacity: 0.5 } : {}
                                }
                                disabled={isSectionToBePlaced}
                                onClick={() =>
                                  toggleDescription(
                                    index,
                                    jobReportTemplate,
                                    setJobReportTemplate
                                  )
                                }
                                className="add-description-button"
                              >
                                + Add Description
                              </button>
                            )}
                          <div>
                            {(template.showDescription ||
                              template?.description) && (
                              <div className="description-field">
                                <div
                                  className="input-container"
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "10px",
                                    border: "1px solid #cccc",
                                    borderRadius: "5px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  {/* <RichTextEditor
                                    id={`description_${index}`}
                                    handleDescriptionChange={
                                      handleDescriptionChange
                                    }
                                    description={template?.description}
                                    index={index}
                                    jobReportTemplate={jobReportTemplate}
                                    setJobReportTemplate={setJobReportTemplate}
                                    isSectionToBePlaced={isSectionToBePlaced}
                                    setDirty={setDirty}
                                    setIsChecklistChanged={setIsChecklistChanged}
                                  /> */}
                                   <ReactQuill
                                      value={jobReportTemplate[index]?.description || ""}
                                      onChange={(content) =>
                                        handleDescriptionChange(
                                          index,
                                          content,
                                          jobReportTemplate,
                                          setJobReportTemplate,
                                          setDirty,
                                          setIsChecklistChanged
                                        )
                                      }
                                      modules={{toolbar: [
                                        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                        [{size: []}],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}, 
                                         {'indent': '-1'}, {'indent': '+1'}],
                                        ['link', 'image', 'video', 'code-block'],
                                        ['clean'],  ['undo', 'redo']
                                      ],
                                      clipboard: {
                                        matchVisual: false,
                                      }}}
                                    />
                                  <button
                                    onClick={() =>
                                      handleDescription(
                                        index,
                                        jobReportTemplate,
                                        setJobReportTemplate
                                      )
                                    }
                                    className="delete-button"
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <Button
                          style={{
                            alignSelf: "center",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          color="danger"
                          disabled={isSectionToBePlaced}
                          onClick={() => {
                            setDeleteIndex(index);
                            setSectionDeleteAlert(true);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {/* <div style={{ display: "flex" }}>
                          <p style={{ marginRight: "10px" }}>Place section</p>
                          <label>
                            <input type="radio" checked disabled />
                            {template?.floor?.id &&
                              " At floor - " + template?.floor.content}{" "}
                            {template?.area?.id &&
                              ", At area - " + template?.area.content}{" "}
                            {template?.section?.id &&
                              " , At section - " +
                                template?.section.content +
                                " , "}{" "}
                            {template?.selectedValue}{" "}
                            {(template?.item?.id && template?.item.content) ||
                              ""}
                          </label>
                        </div> */}
                        { editIndex !==index && (
                          <Button
                            color="primary"
                            disabled={isSectionToBePlaced}
                            className="btn btn-primary float-left m-2"
                            onClick={() => {
                              setCurrentTemplate({ ...template, index });
                              setIsSectionToBePlaced(true);
                              setEditIndex(index);
                            }}
                          >
                            Choose the placement of this section
                          </Button>
                        )}

                        {isSectionToBePlaced && editIndex ===index  &&(
                          <TemplateSectionPosition
                            setIsSectionToBePlaced={setIsSectionToBePlaced}
                            currentTemplate={currentTemplate}
                            job={job}
                            jobType={jobType}
                            jobReportTemplate={jobReportTemplate}
                            setJobReportTemplate={setJobReportTemplate}
                            includeChecklist={includeChecklist}
                            collectionName={collectionName}
                            setIncludeChecklist={setIncludeChecklist}
                            setEditIndex={setEditIndex}
                            floor ={floor}
                            areas = {areas}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <div
            className="form-check mb-3 include-photos-checkbox"
            style={{ marginTop: "10px" }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value={includeChecklist}
              checked={includeChecklist}
              id="defaultCheckChecklist"
              disabled={isSectionToBePlaced}
              onChange={e => setIncludeChecklist(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Add Checklist Details such as Checklist Items, Photos & Notes
            </label>
          </div>
          <div className="form-check mb-3 include-photos-checkbox">
            <input
              className="form-check-input"
              type="checkbox"
              value={includeEsign}
              id="defaultCheckChecklist"
              disabled={isSectionToBePlaced}
              onChange={e => setIncludeEsign(e.target.checked)}
            />
            <label
              className="form-check-label esign-label"
              htmlFor="defaultCheck1"
            >
              Please tick this statement to acknowledge above details are
              correct & proceed with your e-sign on this document
            </label>
          </div>
          {/* {loading && (
            <div className="progress-bar-container">
              <Alert color="primary" className="mb-2">
                <h4 className="alert-heading mb-2">
                  Report generation is in progress monika!
                </h4>
                <p className="mb-0">
                  Your report is being generated - this may take a while
                  depending on the size. Once complete, you can download your
                  report.
                </p>
              </Alert>
              <ProgressBar percentage={progress}/>
            </div>
          )} */}
      
      {loading && (
        <div className="progress-bar-container">
          <Alert color="primary" className="mb-2">
            <h4 className="alert-heading mb-2">Report generation is in progress!</h4>
            <p className="mb-0">
              Your report is being generated - this may take a while depending on the size. Once complete, you can download your report.
            </p>
            <Progress value={progress} animated color="success" />
          </Alert>
        </div>
      )}


          <div className="modal-footer">
            <Button
              color="light"
              disabled={loading || isSectionToBePlaced}
              onClick={() => {
                setShowCertModal(false);
                setJobReportTemplate([]);
              }}
              className="btn btn-light float-left m-2"
              data-dismiss="modal"
            >
              Close
            </Button>
            <Button
              disabled={
                loading ||
                jobReportTemplate.length == 0 ||
                templateName.trim().length == 0 ||
                isDirty ||
                isSectionToBePlaced
              }
              onClick={() => {
                saveTemplate(jobReportTemplate, currentUserProfile.orgAccessId);
              }}
              className="btn btn-primary float-left m-2"
              data-dismiss="modal"
              color="success"
            >
              Save Template
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                setLoading(true);
                await onGenerate({
                  jobReportTemplate: jobReportTemplate || [],
                  includeChecklist,
                  setIncludeChecklist,
                  setLoading,
                  floor,
                  areas
                });
              }}
              className="btn btn-primary float-left m-2"
              data-dismiss="modal"
              disabled={
                !includeEsign ||
                loading ||
                !(jobReportTemplate?.items || jobReportTemplate)?.every(  
                  field => field?.name?.trim() !== ""
                ) ||
                isSectionToBePlaced
              }
            >
              {loading ? "Previewing" : "Preview"}
            </Button>
          </div> 
        </Row>
      </div>
    </>
  );
};

export default CreateTemplateModel;
