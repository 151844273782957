import { Col, Modal, Row, ModalHeader, ModalBody } from "reactstrap";
import * as markerjs2 from "markerjs2";
import { useRef, useState } from "react";
import { fetchChecklistByDocumentId } from "services";
import firebase from "config/firebase";
import cuid from "cuid";
import Compressor from "compressorjs";
import {
  RESIZED_IMAGES_DIMENSIONS,
  RESIZED_IMAGES_PATH,
} from "common/constants";
import { useSelector } from "react-redux";
import { DownloadImage } from "pages/jobs/jobDetails/rooms/DownloadImage";
import { toast } from "react-toastify";
import ProgressLine from "components/ProgressLine";
import SweetAlert from "react-bootstrap-sweetalert";
import { userName } from "pages/platformManagement/org-creation/orgService";

const ImageEditor = ({
  imageSrc,
  setisGallery,
  isGallery,
  job,
  checklistDocumentId,
  sectionId,
  itemId,
  collectionName,
  files,
}) => {
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [percent, setPercent] = useState(0);
  const [confirmAlert, setConfirmAlert] = useState(false);

  const handleConfirm = () => {
    setConfirmAlert(false);
    toggle();
  };

  const handleCancel = () => {
    setConfirmAlert(false);
  };
  const divRef = useRef(null);
  const imgRef = useRef(null);

  const getQuality = fileSizeInMB => {
    return fileSizeInMB > 2 ? 0.2 : fileSizeInMB > 1 ? 0.3 : 0.6;
  };

  const checklistRef = fetchChecklistByDocumentId(
    collectionName,
    job.id,
    checklistDocumentId
  );

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const convertToBase64 = (file, mimeType) => {
    const byteCharacters = atob(file.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  };

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.targetRoot = divRef.current;
      markerArea.settings.defaultColor = "#000000";
      markerArea.settings.defaultColorsFollowCurrentColors = true;
      markerArea.settings.defaultFillColor = "transparent";
      
      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;

      markerArea.availableMarkerTypes = [
        "FrameMarker", 
        "FreehandMarker",
        markerjs2.ArrowMarker,
        "EllipseMarker",
        "HighlightMarker",
        "CalloutMarker",
      ]
      markerArea.addEventListener("beforeclose", event => {
        if (event.markerArea.markers.length > 0) {
          setConfirmAlert(true);
        }else{
          event.preventDefault();
          toggle();
        }
      });

      markerArea.addEventListener("render", async event => {
        try {
          if (imgRef.current && event.markerArea.markers.length > 0) {
            const match = event?.dataUrl?.match(/^data:(.*?);base64,/);
            const mimeType = match ? match[1] : null;

            imgRef.current.src = event?.dataUrl;
            imageSrc = event?.dataUrl;

            const blob = convertToBase64(event?.dataUrl, mimeType);

            await Promise.all(
              files?.map(file => {
                return new Promise(async resolve => {
                  const fileId = cuid();
                  file.id = fileId;
                  const filePath = `${job.orgId}/${job.jobNo}/images/${fileId}`;
                  var fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                  new Compressor(blob, {
                    quality: getQuality(fileSizeInMB),
                    success(result) {
                      const uploadTask = storageRef
                        .child(filePath)
                        .put(result, {
                          contentType: result.type,
                        });
                      uploadTask.on(
                        "state_changed",
                        snap => {
                          const percentUploaded = Math.round(
                            (snap.bytesTransferred / snap.totalBytes) * 100
                          );
                          file.percent = percentUploaded;
                          setPercent(percentUploaded);
                        },
                        err => {
                          console.error(err);
                        },
                        () => {
                          uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(async downloadUrl => {
                              file.downloadUrl = downloadUrl;
                              file.size = formatBytes(result.size);
                              file.type = result.type;
                              resolve(downloadUrl);
                            })
                            .catch(err => {
                              console.error(err);
                            });
                        }
                      );
                    },
                    error(err) {
                      console.log(err.message);
                    },
                  });
                });
              })
            );

            await db.runTransaction(async txn => {
              const checklistDoc = await txn.get(checklistRef);
              const checklist = checklistDoc.data();
              const updatedChecklistItems = checklist.items.map(section => {
                if (section.id == sectionId) {
                  const newSubItems = section.subItems.map(item => {
                    if (item.id === itemId) {
                      files?.map(file => {
                        const resizedImagePath = `${job.orgId}/${job.jobNo}/images/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
                        if (item.attachments) {
                          item.attachments.push({
                            id: file.id,
                            userId: currentUserProfile.id,
                            name: file.name,
                            type: file.type,
                            url: file.downloadUrl,
                            size: file.size,
                            userName: userName(currentUserProfile),
                            resizedImagePath: resizedImagePath,
                            date: new Date(),
                            userId: currentUserProfile?.id,
                          });
                        } else {
                          item.attachments = [
                            {
                              id: file.id,
                              userId: currentUserProfile.id,
                              name: file.name,
                              type: file.type,
                              url: file.downloadUrl,
                              size: file.size,
                              userName: userName(currentUserProfile),
                              resizedImagePath: resizedImagePath,
                              date: new Date(),
                              userId: currentUserProfile?.id,
                            },
                          ];
                        }
                      });
                    }
                    return item;
                  });
                  section.subItems = newSubItems;
                }
                return section;
              });
              txn.update(checklistRef, { items: updatedChecklistItems });
              toast.success("photo updated successfully !", {
                autoClose: 2000,
              });
            });
            toggle();
          }
        } catch (err) {
          console.log(err);
        }
      });
      markerArea.show();
    }
  };

  const toggle = () => {
    setisGallery(!isGallery);
    showMarkerArea();
  };

  const downloadImage = DownloadImage();

  return (
    <Modal
      isOpen={isGallery}
      toggle={toggle}
      size="xl"
      className="modal-fullscreen"
      style={{ marginTop: "-14rem" }}
    >
      <ModalHeader toggle={toggle} style={{ justifyContent: "end" }}>
        <div className="d-flex align-items-center justify-content-between" style={{ marginTop : '-2rem' }}>
          <button
            type="button"
            className="btn btn-lg text-primary"
            onClick={showMarkerArea}
          >
            <i className="fas fa-pencil-alt" style={{ fontSize: "1.5rem" }}></i>
          </button>
          <button
            type="button"
            className="btn btn-lg text-success"
            onClick={() => {
              downloadImage(imageSrc, "image");
            }}
          >
          <i className="far fa-arrow-alt-circle-down" style={{ fontSize: "1.5rem" }}></i>
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="10" className="d-flex align-items-center justify-content-center">
            <div
              ref={divRef}
              className="d-flex imagesss align-items-center justify-content-center"
              style={{ width: "1100px", height: "590px", overflow: "hidden" }}
            >
              <img
                ref={imgRef}
                src={imageSrc}
                alt={`Annotated image from ${imageSrc}`}
                crossOrigin="anonymous"
                style={{width: '100%', height: "100%", objectFit: "contain" }}
                onClick={() => {
                  showMarkerArea();
                }}
              />
            </div>
          </Col>
          <Col sm="2">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <h5 className="m-1 text-danger">Symbols Legend</h5>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-mouse-pointer"></i>
                <p className="m-1">Select Mode</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="far fa-trash-alt"></i>
                <p className="m-1">Delete Changes</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-undo"></i>
                <p className="m-1">Undo</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-search-plus"></i>
                <p className="m-1">zoom in</p>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-search-minus"></i>
                <p className="m-1">zoom out</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="far fa-square"></i>
                <p className="m-1">Frame marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-pencil-alt"></i>
                <p className="m-1">Freehand Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-location-arrow fa-rotate-180"></i>
                <p className="m-1">Arrow Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-circle"></i>
                <p className="m-1">Ellipse Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-highlighter"></i>
                <p className="m-1">Highlight Marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="far fa-comment-alt"></i>
                <p className="m-1">Callout marker</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-check"></i>
                <p className="m-1">Save and Close</p>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fas fa-times"></i>
                <p className="m-1">Close</p>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      {percent > 0 && (
        <ProgressLine percent={percent} setPercent={setPercent} files={files} />
      )}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            handleCancel();
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </Modal>
  );
};
export default ImageEditor;
