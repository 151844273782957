// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputField {
  margin: 10px !important;
  padding: 0px !important; }

.datePickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px; }

.datePickerLabel {
  width: 50%; }
`, "",{"version":3,"sources":["webpack://./src/pages/platformManagement/subscription/createSubscription.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB,EAAA;;AAGzB;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB,EAAA;;AAGlB;EACE,UAAU,EAAA","sourcesContent":[".inputField {\n  margin: 10px !important;\n  padding: 0px !important;\n}\n\n.datePickerContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 20px 0px;\n}\n\n.datePickerLabel {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
