// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phoneNumber input {
  height: auto;
  padding: 3px;
  color: #495057;
  font-size: 12px;
}

.phoneNumber button {
  height: 1rem;
  width: 1rem;
}

.saveOrCancelButton {
  color: white;
  border-radius: 3px;
  height: 1.9rem !important;
  width: 2.3rem !important;
  padding: 6px;
  transform: scale(0.9);
  margin-left: -3px;
}
.addressInput{
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.71094rem !important;
  border-radius: 0.2rem !important;
  width: 15rem !important;
}
.addressWithLabel{
    height: inherit;
    border-radius: 0.2rem !important;
    width: 21rem !important;
}
.action-buttons{
  display: flex;
  margin-top: -2px;
}
@media only screen and (max-width: 768px) {
  .margin-top-avatar {
    margin-top: 86px;
  }
}

.avatar-dropzone {
  min-height: 25;
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
  width: 300px;
}

.doc-dropzone {
  min-height: 25;
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/userProfile/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,wBAAwB;EACxB,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;AACnB;AACA;EACE,iDAAiD;EACjD,kCAAkC;EAClC,gCAAgC;EAChC,gCAAgC;EAChC,uBAAuB;AACzB;AACA;IACI,eAAe;IACf,gCAAgC;IAChC,uBAAuB;AAC3B;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".phoneNumber input {\n  height: auto;\n  padding: 3px;\n  color: #495057;\n  font-size: 12px;\n}\n\n.phoneNumber button {\n  height: 1rem;\n  width: 1rem;\n}\n\n.saveOrCancelButton {\n  color: white;\n  border-radius: 3px;\n  height: 1.9rem !important;\n  width: 2.3rem !important;\n  padding: 6px;\n  transform: scale(0.9);\n  margin-left: -3px;\n}\n.addressInput{\n  min-height: calc(1.5em + 0.5rem + 2px) !important;\n  padding: 0.25rem 0.5rem !important;\n  font-size: 0.71094rem !important;\n  border-radius: 0.2rem !important;\n  width: 15rem !important;\n}\n.addressWithLabel{\n    height: inherit;\n    border-radius: 0.2rem !important;\n    width: 21rem !important;\n}\n.action-buttons{\n  display: flex;\n  margin-top: -2px;\n}\n@media only screen and (max-width: 768px) {\n  .margin-top-avatar {\n    margin-top: 86px;\n  }\n}\n\n.avatar-dropzone {\n  min-height: 25;\n  border: 2px dashed #ced4da;\n  background: #fff;\n  border-radius: 6px;\n  width: 300px;\n}\n\n.doc-dropzone {\n  min-height: 25;\n  border: 2px dashed #ced4da;\n  background: #fff;\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
