import { map } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AVATAR_URL, CLOUD_FUNCTION_URL } from "common/constants";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import {
  dataFromSnapshot,
  getOrgFromDb,
  getUser,
  removeJobParticipants,
  updateJobParticipant,
} from "services";

import {
  REMOVE_USER,
} from "../../../common/constants";
import "../../../common/styles.css";
import DeleteConfirmationPrompt from "../../../components/Common/DeleteConfirmationPrompt";
import {
  defaultAccessOptions,
  defaultRoleOptions,
  invitedUserAccessOptions,
} from "../jobCreation/utils/user";
import {
  sendRemovalEmailNotificationToUser,
} from "services";
import { deleteWorkingDatesForParticipants, fetchWorkingDatesOfparticipants, removeChatNotificationsOfTheJob } from "services/jobService";
import "./TeamMembers.scss";
import * as _ from "lodash";
import generator from "generate-password-browser";
import axios from "axios";
import DateRangePicker from "./DateRangePicker";
import { ParticipantsUi } from "./ParticipantsUi";
import { existingUser, roleChange, invitedUser, notificationService } from "./participantsService";
import ParticipantDetail from "./participantDetail";
export const createOption = label => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export const TeamMembers = ({
  job,
  canEdit,
  collectionName,
  setShowPaticipantsModal,
  participantsModal,
}) => {
  const [isDeleteConfirmationPromptOpen, setIsDeleteConfirmationPromptOpen] =
    useState(false);
  const { currentUserProfile } = useSelector(state => state.profile);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState({
    label: "Installer",
    value: "installer",
  });
  const [access, setAccess] = useState({ label: "Full", value: "full" });
  const [invitedAccess, setInvitedAccess] = useState({
    label: "Tick, Notes, Photos, E-sign",
    value: "ticknotesviachatphotosesign",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToRemove, setUserToRemove] = useState(null);
  const [participants, setParticipants] = useState(job.participants);

  const [isRolesLoading, setRolesLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [accessOptions, setAccessOptions] = useState([]);
  const [invitedAccessOptions, setInvitedAccessOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedParticipant, setselectedParticipant] = useState({})
  const [isOpen, setIsOpen] = useState(false);
  const [manual, setManual] = useState(false);
  const searchRef = React.createRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [removedParticipants, setRemovedParticipants] = useState([]);
  const [workingDates, setWorkingDates] = useState([]);
  useEffect(async () => {
    setRoleOptions(defaultRoleOptions);
    setAccessOptions(defaultAccessOptions);
    setInvitedAccessOptions(invitedUserAccessOptions);
    fetchWorkingDatesOfparticipants(job.id, collectionName)
      .then((participantsWorkingDates) => {
        setWorkingDates(participantsWorkingDates)
      })
      .catch((error) => {
        console.error("Error fetching working dates:", error);
      });
  }, []);
  const onModalClose = () => {
    setShowPaticipantsModal(false);
    setParticipants(job.participants);
    setRemovedParticipants([]);
    setManual(false);
  };
  const onAddExistingUser = existingUser(participants, selectedUser, searchRef, setSelectedUser, role, access, setParticipants, job, collectionName, currentUserProfile);

  const getUserInfo = async email => {
    const snapshot = await getUser(email);
    if (!snapshot.empty) {
      const docs = snapshot.docs.map(doc => dataFromSnapshot(doc));
      return docs[0];
    }
    return null;
  };

  const createInvitedUser = async userInfo => {
    if (userInfo)
      return [
        {
          displayName: name,
          email: email.toLowerCase(),
          mobile,
          id: userInfo.id,
          invited: true,
          isEmailSent: false,
          access: invitedAccess,
          role,
          registered: userInfo.registered,
        },
        null,
      ];
    try {
      const password = userInfo
        ? null
        : generator.generate({
          length: 10,
          numbers: true,
        });
      const user = {
        email: email.toLowerCase(),
        role,
        password,
      };
      const resp = await axios.post(`${CLOUD_FUNCTION_URL}/createUser`, user);
      if (resp.status === 200) {
        const user = await getUserInfo(email.toLowerCase());
        return [
          {
            displayName: name,
            email: email.toLowerCase(),
            mobile,
            id: user.id,
            invited: true,
            isEmailSent: false,
            access: invitedAccess,
            role,
            registered: user.registered,
          },
          password,
        ];
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const onAddInvitedUser = invitedUser(email, participants, searchRef, options, createInvitedUser, setParticipants, setEmail, setMobile, setName, job, collectionName, currentUserProfile);

  const onAccessChange = async (userId, updatedData) => {
    try {
      await updateJobParticipant(job.id, userId, updatedData, collectionName);
      toast.success(`Changed the user's access successfully`, {
        autoClose: 2000,
      });
    } catch (err) {
      console.log("onAccessChange", err);
      toast.error(`Failed to update the user's access`, {
        autoClose: 2000,
      });
    }
  };

  const onRoleChange = roleChange(participants, job, collectionName, currentUserProfile);
  const onRemoveUser = async user => {
    setUserToRemove(user);
    setIsDeleteConfirmationPromptOpen(true);
  };
  const onRemoveUserConfirmation = async () => {
    setParticipants([
      ...participants.filter(user => user.id !== userToRemove.id),
    ]);
    setRemovedParticipants([...removedParticipants, userToRemove]); // TODO: REMOVE THIS FOR GRANULAR UPDATE
    try {
      setIsDeleteConfirmationPromptOpen(false);
      await removeChatNotificationsOfTheJob(job.id);
      await deleteWorkingDatesForParticipants(job.id, userToRemove.id, collectionName);
      await removeJobParticipants(job.id, userToRemove, collectionName);
      toast.success("Removed the user successfully", {
        autoClose: 2000,
      });
      const snapshot = await getOrgFromDb(job.orgId);
      const { name: orgName } = snapshot.data();
      if (job.accessIds.includes(userToRemove.id)) {
        await notificationService(job, collectionName, REMOVE_USER, orgName, userToRemove);
        await sendRemovalEmailNotificationToUser(
          orgName,
          job.orgId,

          {
            jobNo: job.jobNo,
            jobAddress: job.jobAddress,
            phone: job.client.mobile ?? "",
            email: job.client?.email ?? "",
          },
          {
            displayName: userToRemove.displayName,
            email: userToRemove.email,
          },
          currentUserProfile
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to remove the user", {
        autoClose: 2000,
      });
    }
  };

  const handleCreate = inputValue => {
    setRolesLoading(true);
    const newOption = createOption(inputValue);
    setRoleOptions([...roleOptions, newOption]);
    setRolesLoading(false);
    setRole(newOption);
  };
  const getWorkDates = (userId) => {
    const participant = workingDates?.find(user => user.userId === userId);
    if (!participant) {
      return {
        startDate: "",
        endDate: "",
        userId: userId
      };
    }
    return participant;
  }
  const participantWorkDates = user => {
    const participant = getWorkDates(user.id);
    return (
      <DateRangePicker
        user={participant}
        collectionName={collectionName}
        jobId={job.id}
        workingDates={workingDates}
      />
    );
  };
  const isValidEmail = (email) => {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  return (
    <>
      {participantsModal ? (
        ParticipantsUi(
          onModalClose,
          searchRef,
          isDropdownOpen,
          setIsDropdownOpen,
          setSelectedUser,
          isRolesLoading,
          setRole,
          handleCreate,
          roleOptions,
          role,
          accessOptions,
          setAccess,
          access,
          selectedUser,
          onAddExistingUser,
          manual,
          setManual,
          name,
          setName,
          email,
          setEmail,
          mobile,
          setMobile,
          invitedAccessOptions,
          setInvitedAccess,
          invitedAccess,
          onAddInvitedUser,
          job,
          onRemoveUser,
          onAccessChange,
          onRoleChange,
          participantWorkDates,
        )
      ) : (
        <Card>
          <CardBody className="job-detail-height ">
            <CardTitle className="mb-4">
              Participants{" "}
              {canEdit && (
                <Button
                  color="primary"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setShowPaticipantsModal(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </CardTitle>

            <div className="table-responsive custom-table-responsive ">
              <Table className="table align-middle table-nowrap ">
                <tbody>
                  {map(job.participants, (member) => (
                    <tr key={"_member_" + member.id} onClick={() => { setselectedParticipant({ ...member }), setIsOpen(true) }}>
                      <td style={{ width: "50px" }}>
                        <img
                          src={
                            member.photoURL
                              ? member.photoURL
                              : `${AVATAR_URL}&name=${member.firstName && member.lastName
                                ? `${member.firstName[0]}${member.lastName[0]}`
                                : member.displayName && member.displayName
                              }`
                          }
                          className="rounded-circle avatar-xs"
                          alt=""
                        />  
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          {(member?.firstName || member?.lastName
                            ? `${member?.firstName} ${member?.lastName}`.charAt(0).toUpperCase() + `${member?.firstName} ${member?.lastName}`.slice(1)
                            : (member?.displayName || member?.displayName.includes('@')
                              ? member?.displayName.split('@')[0].charAt(0).toUpperCase() + member?.displayName.split('@')[0].slice(1)
                              : member?.displayName.charAt(0).toUpperCase() + member?.displayName.slice(1)))}
                        </h5>
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          {member?.email ? member.email : "N/A"}
                        </h5>
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          {member?.mobile ? member?.mobile : "N/A"}
                        </h5>
                      </td>
                      <td>
                        <div>
                          <p className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                            {member.role?.label ? member.role?.label : "N/A"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      )}
      {isOpen && <ParticipantDetail isOpen={isOpen} setIsOpen={setIsOpen} obj={typeof (selectedParticipant) === 'object' ? selectedParticipant : {}} />}
      {isDeleteConfirmationPromptOpen && (
        <DeleteConfirmationPrompt
          onCancel={() => setIsDeleteConfirmationPromptOpen(false)}
          onConfirm={onRemoveUserConfirmation}
          title={`Are you sure you want to remove the User?`}
          renderDescription={() => {
            return (
              <div>
                Removing <strong>{userToRemove?.displayName}</strong> from the
                job
              </div>
            );
          }}
          confirmBtnText={"Remove"}
        />
      )}
    </>
  );
};

TeamMembers.propTypes = {
  team: PropTypes.array,
};

export default TeamMembers;

