import React from 'react';
import { map } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { AVATAR_URL } from '../../common/constants';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

const UserAvatars = ({ participants }) => {
    const MAX_DISPLAY_USERS = 3;

    const recentParticipants = [...participants.raw].reverse().slice(0, MAX_DISPLAY_USERS);
    
    return (
        <div className="avatar-group">
            {map(recentParticipants, (member) => {
                const { displayName, id, photoURL, firstName, lastName } = JSON.parse(member);
                return (
                    <div className="avatar-group-item" key={id}>
                        <div
                            className="team-member d-inline-block"
                            id={`member-${id}`}
                        >
                            <img
                                // src={photoURL || `${AVATAR_URL}&name=${displayName}`}
                                src={
                                    photoURL
                                      ? photoURL
                                      : `${AVATAR_URL}&name=${
                                          firstName && lastName
                                            ? `${firstName[0]}${lastName[0]}`
                                            : displayName && displayName
                                        }`
                                  }
                                className="rounded-circle avatar-xs"
                                alt={displayName}
                            />
                            <UncontrolledTooltip
                                placement="top"
                                target={`member-${id}`}
                            >
                                {displayName}
                            </UncontrolledTooltip>
                        </div>
                    </div>
                );
            })}
            {participants.raw.length > MAX_DISPLAY_USERS && (
                <UncontrolledDropdown>
                    <DropdownToggle href="#" className="card-drop users-count" tag="i">
                        +{participants.raw.length - MAX_DISPLAY_USERS} more
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-start">
                        <DropdownItem header>All Participants</DropdownItem>
                        <div className='avatar-group'>
                            {map(participants.raw, (member) => {
                                const { displayName, id, photoURL, firstName, lastName } = JSON.parse(member);
                                return (
                                    <div className="" key={id}>
                                        <div className="team-member d-inline-block" id={`member-${id}`}>
                                            <img
                                                src={
                                                    photoURL
                                                      ? photoURL
                                                      : `${AVATAR_URL}&name=${
                                                          firstName && lastName
                                                            ? `${firstName[0]}${lastName[0]}`
                                                            : displayName && displayName
                                                        }`
                                                  }
                                                className="rounded-circle avatar-xs"
                                                alt={displayName}
                                            />
                                            <UncontrolledTooltip placement="top" target={`member-${id}`}>
                                                {displayName}
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </div>
    );
};

export default UserAvatars;
