// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  z-index: 1020 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 99%;
  height: 95%;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobCreation/jobLinkage/linkJobStyles.scss"],"names":[],"mappings":"AAAA;EACI,wBAAwB;EACxB,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;EACb,2CAA2C;EAC3C,UAAU;EACV,WAAW;EACX,cAAc,EAAA","sourcesContent":[".custom-modal {\n    z-index: 1020 !important;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 20px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n    width: 99%;\n    height: 95%;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
