import React from "react";
import { Modal, Form } from "reactstrap";

import JobPhotoUploader from "pages/userProfile/JobPhotoUploader";
import { AVATAR_URL } from "common/constants";

export default function ParticipantDetail({ isOpen, setIsOpen, obj }) {

  return (
    <Modal isOpen={isOpen} className="participant-modal-content" centered>
      <div className="participant-modal px-2 py-4">
        <div className="participant-detail-heading">
          <h3 className="mt-0" id="myModalLabel">
            Participants Detail
          </h3>
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="p-2 p-sm-4 w-100">
          <div className="participant-form-card w-100">
            <Form className="w-100">
              <div className="align-items-center name-detail gap-3">
                <div className="participant-image">
                  <img
                    src={
                      obj.photoURL
                        ? obj.photoURL
                        : `${AVATAR_URL}&name=${obj.firstName && obj.lastName
                          ? `${obj.firstName[0]}${obj.lastName[0]}`
                          : obj.displayName && obj.displayName
                        }`
                    }
                    className="rounded-circle avatar-sm"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="mb-2 mt-0 fs-5">
                    {obj?.firstName || obj?.lastName
                      ? (`${obj?.firstName} ${obj?.lastName}`.charAt(0).toUpperCase() + `${obj?.firstName} ${obj?.lastName}`.slice(1))
                      : (obj?.displayName.includes('@')
                        ? obj?.displayName.split('@')[0].charAt(0).toUpperCase() + obj?.displayName.split('@')[0].slice(1)
                        : obj?.displayName.charAt(0).toUpperCase() + obj?.displayName.slice(1))}
                  </h4>
                  <p className="text-muted mt-0 mb-0">{obj?.email}</p>
                </div>
              </div>

              <div className="mt-4 pt-2 role-detail flex-nowrap">
                <div className="row gy-3">
                  <div className="col-md-6">
                    <div className="modal-card">
                      <strong>Mobile</strong>:
                      <span className="text-wrap text-break">{obj?.mobile || "NA"}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-card">
                      <strong>Role</strong>
                      :
                      <span className="text-wrap text-break me-1">
                        {obj.role?.label}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-card">
                      <strong>Access</strong>:{" "}
                      <span className="text-wrap text-break me-1">
                        {obj?.access?.label}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
