import { isValidEmail } from "common/utils";
import cuid from "cuid";
import MuiPhoneNumber from "mui-phone-number";
import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { addClientToDb, updateClientInDb } from "services";
import "../../common/styles.css";
import { countries } from "utils/countries";

export default function CreateNewClient({
  showCreateNewClient,
  setShowCreateNewClient,
  isEditClient,
  setEditClient,
  client,
  setClient,
  currentUserProfile,
  allClients,
}) {
  const [address, setAddress] = useState(client.address || "");
  const [suburb, setSuburb] = useState(client.suburb || "");

  const [isValidForm, setIsValidForm] = useState(isEditClient ? true : false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [
    showSecondaryEmailAlreadyExistMessage,
    setShowSecondaryEmailAlreadyExistMessage,
  ] = useState(false);
  const [
    showPrimaryEmailAlreadyExistMessage,
    setShowPrimaryEmailAlreadyExistMessage,
  ] = useState(false);
  const [businessMobileErrorMessage, setBusinessMobileErrorMessage] =
    useState(false);
  const [contactMobileErrorMessage, setContactMobileErrorMessage] =
    useState(false);

  useEffect(() => {
    if (client?.phone && client?.phone !== "+") {
      handlePhoneNumberChange(client?.phone, client?.phoneDialCode)
    }
    if (client?.telephone && client?.telephone !== "+") {
      handleTelephoneChange(client?.telephone, client?.telephoneDialCode)
    }
    if (client?.secondaryPhone && client?.secondaryPhone !== "+") {
      handleSecondaryPhoneChange(client?.secondaryPhone, client?.secondaryPhoneDialCode)
    }
    if (client?.secondaryTelephone && client?.secondaryTelephone !== "+") {
      handleSecondaryTelephoneChange(client?.secondaryTelephone, client?.secondaryTelephoneDialCode)
    }
  }, []);

  useEffect(() => {
    setIsValidForm(
      client?.name &&
      client?.email &&
      isValidEmail(client?.email) &&
      client?.primaryEmail &&
      isValidEmail(client?.primaryEmail) &&
      !(
        client?.primaryEmail?.trim().toLowerCase() ===
        client?.secondaryEmail?.trim().toLowerCase()
      ) &&
      (client?.secondaryEmail
        ? isValidEmail(client?.secondaryEmail) &&
        !(
          client?.email?.trim().toLowerCase() ===
          client?.secondaryEmail?.trim().toLowerCase()
        )
        : true) &&
      client?.phone &&
      client?.phone !== "+" &&
      client?.phone?.replace(`+${client?.phoneDialCode}`, '')?.length <= 10 &&
      client?.phone?.replace(`+${client?.phoneDialCode}`, '')?.length > 4 &&
      (client?.secondaryPhone
        ? client?.secondaryPhone !== "+" &&
        !(client?.phone?.trim() === client?.secondaryPhone?.trim()) &&
        client?.secondaryPhone?.replace(`+${client?.secondaryPhoneDialCode}`, '')?.length <= 10 &&
        client?.secondaryPhone?.replace(`+${client?.secondaryPhoneDialCode}`, '')?.length > 4
        : true) &&
      (client?.telephone
        ? client?.telephone !== "+" &&
        client?.telephone?.replace(`+${client?.telephoneDialCode}`, '')?.length <= 10 &&
        client?.telephone?.replace(`+${client?.telephoneDialCode}`, '')?.length > 4
        : true) &&
      (client?.secondaryTelephone
        ? client?.secondaryTelephone !== "+" &&
        !(
          client?.telephone?.trim() ===
          client?.secondaryTelephone?.replace(`+${client?.secondaryTelephoneDialCode}`, '')?.trim()
        ) &&
        client?.secondaryTelephone?.replace(`+${client?.secondaryTelephoneDialCode}`, '')?.length <= 10 &&
        client?.secondaryTelephone?.replace(`+${client?.secondaryTelephoneDialCode}`, '')?.length > 4
        : true) &&
      address &&
      address !== ""
    );
  }, [client, address]);

  const isUniqueClientEmail = () => {
    const existingClients = allClients.filter(existingClient => {
      return (
        existingClient.email?.trim()?.toLowerCase() ===
        client.email?.trim()?.toLowerCase() ||
        existingClient?.primaryEmail?.trim()?.toLowerCase() ===
        client?.email?.trim()?.toLowerCase() ||
        existingClient?.secondaryEmail?.trim()?.toLowerCase() ===
        client?.email?.trim()?.toLowerCase()
      );
    });
    if (isEditClient) {
      if (existingClients.length === 1) {
        return existingClients[0].id === client.id;
      }
    }
    return existingClients.length <= 0;
  };
  const isUniqueClientSecondaryEmail = () => {
    if (client?.secondaryEmail) {
      const existingClients = allClients.filter(existingClient => {
        return client?.secondaryEmail
          ? existingClient?.secondaryEmail?.trim()?.toLowerCase() ===
          client?.secondaryEmail?.trim()?.toLowerCase() ||
          existingClient.email?.trim()?.toLowerCase() ===
          client?.secondaryEmail?.trim()?.toLowerCase() ||
          existingClient?.primaryEmail?.trim()?.toLowerCase() ===
          client?.secondaryEmail?.trim()?.toLowerCase()
          : true;
      });
      if (isEditClient) {
        if (existingClients.length === 1) {
          return existingClients[0].id === client.id;
        }
      }
      return existingClients.length <= 0;
    }
  };
  const isUniqueClientPrimaryEmail = () => {
    if (client?.primaryEmail) {
      const existingClients = allClients.filter(existingClient => {
        return client?.primaryEmail
          ? existingClient?.primaryEmail?.trim()?.toLowerCase() ===
          client?.primaryEmail?.trim()?.toLowerCase() ||
          existingClient?.secondaryEmail?.trim()?.toLowerCase() ===
          client?.primaryEmail?.trim()?.toLowerCase() ||
          existingClient?.email?.trim()?.toLowerCase() ===
          client?.primaryEmail?.trim()?.toLowerCase()
          : true;
      });
      if (isEditClient) {
        if (existingClients.length === 1) {
          return existingClients[0].id === client.id;
        }
      }
      return existingClients.length <= 0;
    }
  };
  const handleSaveClick = () => {
    if (!isUniqueClientEmail()) {
      setShowErrorMessage(true);
      setIsValidForm(false);
      return;
    }

    if (client?.primaryEmail && !isUniqueClientPrimaryEmail()) {
      setShowPrimaryEmailAlreadyExistMessage(true);
      setIsValidForm(false);
      return;
    }

    if (client?.secondaryEmail && !isUniqueClientSecondaryEmail()) {
      setShowSecondaryEmailAlreadyExistMessage(true);
      setIsValidForm(false);
      return;
    }

    const newUser = {
      id: cuid(),
      name: client?.name,
      contactName: client?.contactName || "",
      phone: client?.phone && client?.phone !== "+" ? client?.phone : "",
      telephone:
        client?.telephone && client?.telephone !== "+" ? client?.telephone : "",
      secondaryPhone:
        client?.secondaryPhone && client?.secondaryPhone !== "+"
          ? client?.secondaryPhone
          : "",
      secondaryTelephone:
        client?.secondaryTelephone && client?.secondaryTelephone !== "+"
          ? client?.secondaryTelephone
          : "",
      email: client?.email,
      primaryEmail: client?.primaryEmail || "",
      secondaryEmail: client?.secondaryEmail || "",
      address,
      type: "client",
      abn: client?.abn || "",
      registeredLicenseNumber: client?.registeredLicenseNumber || "",
      phoneDialCode: client?.phoneDialCode || "",
      telephoneDialCode: client?.telephoneDialCode || "",
      secondaryPhoneDialCode: client?.secondaryPhoneDialCode || "",
      secondaryTelephoneDialCode: client?.secondaryTelephoneDialCode || "",
      postcode: client?.postcode || "",
      suburb: suburb || "",
      country: client?.country || "",
      firstname: client?.firstname || "",
      lastname: client?.lastname || "",
    };

    if (!isEditClient) {
      addClientToDb(currentUserProfile.orgAccessId, newUser);
    } else {
      const updateUser = { ...newUser, id: client.id };
      updateClientInDb(currentUserProfile.orgAccessId, updateUser);
    }

    setEditClient(false);
    setShowCreateNewClient(false);
  };
  const [phoneError, setPhoneError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);
  const [secondaryPhoneError, setSecondaryPhoneError] = useState(false);
  const [secondaryTelephoneError, setSecondaryTelephoneError] = useState(false);

  const handlePhoneNumberChange = (value, dialCode) => {
    const phoneWithoutDialCode = value?.replace(`+${dialCode}`, '');
    if (
      !!dialCode &&
      value?.replace("+", "")?.length === dialCode?.length
    ) {
      setPhoneError(false);
      setClient({
        ...client,
        phone: '',
        phoneDialCode: '',
      });
      return;
    } else {
      if (
        // value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4
        phoneWithoutDialCode?.length >= 7 && phoneWithoutDialCode?.length <= 10
      ) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    }
    setClient({
      ...client,
      phone: value,
      phoneDialCode: dialCode
    });
  };

  const handleTelephoneChange = (value, dialCode) => {
    if (
      !!dialCode &&
      value?.replace("+", "")?.length === dialCode?.length
    ) {
      setTelephoneError(false);
      setClient({
        ...client,
        telephone: '',
        telephoneDialCode: '',
      });
      return;
    } else {
      if (value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4) {
        setTelephoneError(false);
      } else {
        setTelephoneError(true);
      }
    }
    setClient({
      ...client,
      telephone: value,
      telephoneDialCode: dialCode
    });
  };

  const handleSecondaryPhoneChange = (value, dialCode) => {
    const phoneWithoutDialCode = value?.replace(`+${dialCode}`, '');
    if (
      !!dialCode &&
      value?.replace("+", "")?.length === dialCode?.length
    ) {
      setSecondaryPhoneError(false);
      setClient({
        ...client,
        secondaryPhone: '',
        secondaryPhoneDialCode: '',
      });
      return;
    } else {
      if (
        // value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4
        phoneWithoutDialCode?.length >= 7 && phoneWithoutDialCode?.length <= 10
      ) {
        setSecondaryPhoneError(false);
      } else {
        setSecondaryPhoneError(true);
      }
    }
    setClient({
      ...client,
      secondaryPhone: value,
      secondaryPhoneDialCode: dialCode
    });
  };

  const handleSecondaryTelephoneChange = (value, dialCode) => {
    const phoneWithoutDialCode = value?.replace(`+${dialCode}`, '');
    if (
      !!dialCode &&
      value?.replace("+", "")?.length === dialCode?.length
    ) {
      setSecondaryTelephoneError(false);
      setClient({
        ...client,
        secondaryTelephone: '',
        secondaryTelephoneDialCode: '',
      });
      return;
    } else {
      if (value?.replace(`+${dialCode}`, '')?.length <= 10 && value?.replace(`+${dialCode}`, '')?.length > 4) {
        setSecondaryTelephoneError(false);
      } else {
        setSecondaryTelephoneError(true);
      }
    }
    setClient({
      ...client,
      secondaryTelephone: value,
      secondaryTelephoneDialCode: dialCode
    });
  };
  return (
    <Modal
      isOpen={showCreateNewClient}
      toggle={() => setShowCreateNewClient(!showCreateNewClient)}
      size="xl"
      style={{ marginTop: "-8%" }}
    >
      <ModalHeader
        toggle={() => setShowCreateNewClient(!showCreateNewClient)}
        tag="h4"
      >
        {isEditClient ? "Edit Client" : "Add Client"}
      </ModalHeader>

      <ModalBody>
        <Row form>
          <Col>
            <Row>
              <>
                <Col lg="6" md="6">
                  <Label>Business Name</Label>
                  <Input
                    name="name"
                    type="text"
                    value={client?.name || ""}
                    onChange={e => {
                      setClient({
                        ...client,
                        name: e.target.value,
                      });
                    }}
                  />
                  {!client.name && (
                    <small className="requiredError">{"*Required"}</small>
                  )}
                </Col>
                <Col lg="6" md="6">
                  <div className="mb-3">
                    <PlacesAutocomplete
                      value={address || ""}
                      onChange={addr => {
                        setAddress(addr);
                      }}
                      onSelect={addr => {
                        setAddress(addr);
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Label>Business Address</Label>
                          <Input
                            id="address"
                            {...getInputProps({
                              placeholder: "Search address ...",
                            })}
                          />
                          {(!address || address === "") && (
                            <p className="requiredError">*Required</p>
                          )}

                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={index}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Col>
              </>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Business/Company Number</Label>
                  <Input
                    name="name"
                    type="text"
                    value={client?.abn || ""}
                    onChange={e => {
                      setClient({
                        ...client,
                        abn: e.target.value || "",
                      });
                    }}
                  />
                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Registered Licence Number (if Applicable)</Label>
                  <Input
                    name="name"
                    type="text"
                    value={client?.registeredLicenseNumber || ""}
                    onChange={e => {
                      setClient({
                        ...client,
                        registeredLicenseNumber: e.target.value || "",
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Business Email</Label>
                  <Input
                    name="name"
                    type="email"
                    value={client?.email || ""}
                    onChange={e => {
                      setShowErrorMessage(false);
                      setClient({
                        ...client,
                        email: e.target.value,
                      });
                    }}
                  />
                  {!client?.email ? (
                    <small className="requiredError">{"*Required"}</small>
                  ) : (
                    !isValidEmail(client?.email) && (
                      <small className="requiredError">{"Invalid email"}</small>
                    )
                  )}

                  {client?.email &&
                    client?.secondaryEmail?.trim().toLowerCase() ===
                    client?.email?.trim().toLowerCase() && (
                      <p className="requiredError">
                        Primary Email and Secondary Email should not be same
                      </p>
                    )}
                  {(
                    showErrorMessage && (
                      <p className="requiredError">Email already exists</p>
                    )
                  )}

                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <PlacesAutocomplete
                    value={suburb || ""}
                    onChange={addr => {
                      setSuburb(addr);
                    }}
                    onSelect={addr => {
                      setSuburb(addr);
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Label>Suburb</Label>
                        <Input
                          id="suburb"
                          {...getInputProps({
                            placeholder: "Search suburb ...",
                          })}
                        />
                        {/* {(!suburb || suburb === "") && (
                            <p className="requiredError">*Required</p>
                          )} */}

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Country</Label>
                  <Input
                    name="country"
                    type="select"
                    value={client?.country || ""}
                    onChange={e => {
                      setShowErrorMessage(false);
                      setClient({
                        ...client,
                        country: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select a Country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.label}>
                        {country.label}
                      </option>
                    ))}
                  </Input>
                  {!client?.country && (
                    <small className="requiredError">{"*Required"}</small>
                  )}
                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Post code</Label>
                  <Input
                    name="postcode"
                    type="number"
                    value={client?.postcode || ""}
                    onChange={e => {
                      setShowErrorMessage(false);
                      setClient({
                        ...client,
                        postcode: e.target.value,
                      });
                    }}
                  />
                  {!client?.postcode && (
                    <small className="requiredError">{"*Required"}</small>
                  )}

                </div>
              </Col>

            </Row>

            <Row>
              <Col lg="6" md="6">
                <Label>First Name</Label>
                <Input
                  name="firstname"
                  type="text"
                  value={client?.firstname || ""}
                  onChange={e => {
                    setClient({
                      ...client,
                      firstname: e.target.value,
                    });
                  }}
                />
                {!client.firstname && (
                  <small className="requiredError">{"*Required"}</small>
                )}
              </Col>
              <Col lg="6" md="6">
                <Label>Last Name</Label>
                <Input
                  name="lastname"
                  type="text"
                  value={client?.lastname || ""}
                  onChange={e => {
                    setClient({
                      ...client,
                      lastname: e.target.value,
                    });
                  }}
                />
                {!client.lastname && (
                  <small className="requiredError">{"*Required"}</small>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Primary Email</Label>
                  <Input
                    name="name"
                    type="email"
                    value={client?.primaryEmail || ""}
                    onChange={e => {
                      setShowPrimaryEmailAlreadyExistMessage(false);
                      setClient({
                        ...client,
                        primaryEmail: e.target.value,
                      });
                    }}
                  />

                  {client?.primaryEmail &&
                    client?.secondaryEmail?.trim().toLowerCase() ===
                    client?.primaryEmail?.trim().toLowerCase() && (
                      <p className="requiredError">
                        Primary Email and Secondary Email should not be same
                      </p>
                    )}
                  {(
                    showPrimaryEmailAlreadyExistMessage && (
                      <p className="requiredError">Email already exists</p>
                    )
                  )}
                  {!client?.primaryEmail ? (
                    <small className="requiredError">{"*Required"}</small>
                  ) : (
                    !isValidEmail(client?.primaryEmail) && (
                      <small className="requiredError">{"Invalid email"}</small>
                    )
                  )}
                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label>Secondary Email</Label>
                  <Input
                    name="name"
                    type="email"
                    value={client?.secondaryEmail || ""}
                    onChange={e => {
                      setShowSecondaryEmailAlreadyExistMessage(false);
                      setClient({
                        ...client,
                        secondaryEmail: e.target.value,
                      });
                    }}
                  />
                  {client?.secondaryEmail
                    && !isValidEmail(client?.secondaryEmail) && (
                      <small className="requiredError">
                        {"Invalid email"}
                      </small>
                    )
                  }
                  {client?.secondaryEmail &&
                    client?.primaryEmail?.trim().toLowerCase() ===
                    client?.secondaryEmail?.trim().toLowerCase() && (
                      <p className="requiredError">
                        Primary Email and Secondary Email should not be same
                      </p>
                    )}

                  {client?.secondaryEmail &&
                    client?.secondaryEmail?.trim().toLowerCase() ===
                    client?.email?.trim().toLowerCase() && (
                      <p className="requiredError">
                        Business Email and Secondary Email should not be same
                      </p>
                    )}
                  {showSecondaryEmailAlreadyExistMessage && (
                    <p className="requiredError">Email already exists</p>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6">
                <div>
                  <Label>Primary Contact Phone Number/s:</Label>
                  <div className="mb-3">
                    <Label>Mobile</Label>
                    <MuiPhoneNumber
                      name="phone"
                      label="Mobile"
                      autoFocus={false}
                      autoFormat={false}
                      defaultCountry={"au"}
                      variant="outlined"
                      value={client?.phone || ""}
                      size="small"
                      fullWidth
                      onChange={(text, country) => {
                        setBusinessMobileErrorMessage(false);
                        handlePhoneNumberChange(text, country?.dialCode);
                      }}
                      error={phoneError}
                    />
                    {!client.phone ||
                      client.phone === "+" ||
                      client?.phone?.length < 4 ? (
                      <p className="requiredError">*Required</p>
                    ) : (
                      phoneError && (
                        <small className="requiredError">
                          {"*Invalid Phone number"}
                        </small>
                      )
                    )}
                    {client?.phone &&
                      (client?.phone?.trim().toLowerCase() ===
                        client?.secondaryPhone?.trim().toLowerCase() ? (
                        <p className="requiredError">
                          Business and Contact mobile number should not be same
                        </p>
                      ) : null)}
                  </div>
                  <div className="mb-3">
                    <Label>Telephone</Label>
                    <MuiPhoneNumber
                      name="phone"
                      label="Telephone"
                      autoFocus={false}
                      autoFormat={false}
                      defaultCountry={"au"}
                      variant="outlined"
                      value={client?.telephone || ""}
                      size="small"
                      fullWidth
                      onChange={(text, country) =>
                        handleTelephoneChange(text, country?.dialCode)
                      }
                      error={telephoneError}
                    />
                    {telephoneError && (
                      <small className="requiredError">
                        {"*Invalid Telephone  number"}
                      </small>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6">
                <div>
                  <Label>Secondary Contact Phone Number/s:</Label>
                  <div className="mb-3">
                    <Label>Mobile</Label>
                    <MuiPhoneNumber
                      name="phone"
                      label="Mobile"
                      autoFocus={false}
                      autoFormat={false}
                      defaultCountry={"au"}
                      variant="outlined"
                      value={client?.secondaryPhone || ""}
                      size="small"
                      fullWidth
                      onChange={(text, country) => {
                        setContactMobileErrorMessage(false);
                        handleSecondaryPhoneChange(text, country?.dialCode);
                      }}
                      error={secondaryPhoneError}
                    />
                    {client?.secondaryPhone &&
                      (client?.phone?.trim().toLowerCase() ===
                        client?.secondaryPhone?.trim().toLowerCase() ? (
                        <p className="requiredError">
                          Business and Contact mobile number should not be same
                        </p>
                      ) : null)}
                    {secondaryPhoneError && (
                      <small className="requiredError">
                        {"*Invalid Phone number"}
                      </small>
                    )}
                  </div>
                  <br />
                  <div className="mb-3">
                    <Label>Telephone</Label>
                    <MuiPhoneNumber
                      name="phone"
                      label="Telephone"
                      autoFocus={false}
                      autoFormat={false}
                      defaultCountry={"au"}
                      variant="outlined"
                      value={client?.secondaryTelephone || ""}
                      size="small"
                      fullWidth
                      onChange={(text, country) =>
                        handleSecondaryTelephoneChange(text, country?.dialCode)
                      }
                      error={secondaryTelephoneError}
                    />
                    {secondaryTelephoneError && (
                      <small className="requiredError">
                        {"*Invalid Telephone  number"}
                      </small>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-user"
              disabled={!isValidForm}
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
}
