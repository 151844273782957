// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textLayer {
  display: none; }

.textPdf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content; }

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; }

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  margin-bottom: 0 !important; }

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/PdfStyle.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;;AAEf;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB,EAAA;;AAGrB;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,UAAU,EAAA;;AAGZ;EACE,2BAA2B,EAAA;;AAE7B;EACE,qBAAqB,EAAA","sourcesContent":[".textLayer {\n  display: none;\n}\n.textPdf {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: max-content;\n}\n\n.modal-content {\n  background-color: #fefefe;\n  margin: 15% auto;\n  padding: 20px;\n  border: 1px solid #888;\n  width: 80%;\n}\n\n.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {\n  margin-bottom: 0 !important;\n}\n.css-1x51dt5-MuiInputBase-input-MuiInput-input{\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
