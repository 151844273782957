// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridContainer {
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  grid-gap: 10px;
  padding: 20px;

  @media (max-width: 850px) {
    grid-template-columns: repeat(calc(var(--col-count) - 1), 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(calc(var(--col-count) - 2), 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/dnd/gridContainer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,oDAAoD;EACpD,cAAc;EACd,aAAa;;EAEb;IACE,8DAA8D;EAChE;;EAEA;IACE,8DAA8D;EAChE;AACF","sourcesContent":[".GridContainer {\n  max-width: 800px;\n  display: grid;\n  grid-template-columns: repeat(var(--col-count), 1fr);\n  grid-gap: 10px;\n  padding: 20px;\n\n  @media (max-width: 850px) {\n    grid-template-columns: repeat(calc(var(--col-count) - 1), 1fr);\n  }\n\n  @media (max-width: 650px) {\n    grid-template-columns: repeat(calc(var(--col-count) - 2), 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
