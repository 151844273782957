import {
  dataFromSnapshot,
  getUserProfile,
  mergeAccounts,
  sendEmailVerification,
  setUserProfileData,
} from "services";
import firebase from "../config/firebase";
import {
  loginError,
  loginSuccess,
  loginUser,
  registerError,
  registerUser,
  resetError,
  resetSuccess,
  logoutUser,
} from "./../store/actions";
import { store } from "./../store/configureStore";
import {
  APPROVED,
  USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE,
  WEB_USER,
} from "common/constants";
import { fetchOrgSubscriptionInfoFromDb } from "./subscriptionsService";
export async function signInWithEmail(creds) {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(creds.email.trim(), creds.password.trim())
      .then(userData => {
        const user = userData.user;
       
        const userprofile = getUserProfile(user?.uid);

        userprofile.onSnapshot(snapshot => {
          const profile = dataFromSnapshot(snapshot);
          if(profile?.type === WEB_USER && profile?.orgAccessId && profile?.orgStatus === APPROVED){ 
             const subscriptionsRef = fetchOrgSubscriptionInfoFromDb(profile.orgAccessId);
             subscriptionsRef.onSnapshot(snapshot => {
              const subscriptionSnap = snapshot.docs.map(doc => dataFromSnapshot(doc));
              const subscription = subscriptionSnap[0];
              console.log("subscription", subscription)
              // check if subscription is active using endDate 
              if(subscription?.endDate <= new Date()){
                store.dispatch(
                  loginError(
                    new Error(
                      USER_DO_NOT_HAVE_ANY_ACTIVE_LICENSE
                    )
                  )
                );
                store.dispatch(logoutUser());
                signOutFirebase();
                return;
              }
            });
           }

          if (profile && profile?.registered) {
            
            store.dispatch(loginUser());

            return;
          }
          if (user && user?.emailVerified) {
            const firstName = user.displayName
              .match(/firstName:(.*?)lastName:/)[1]
              .trim();
            const lastName = user.displayName.match(/lastName:(.*)/)[1].trim();
            const displayName= firstName+ ' '+ lastName;
            store.dispatch(loginUser());
            setUserProfileData({ ...user,firstName, lastName,displayName, registered: true });
            mergeAccounts();

            return;
          }
          if (user && user?.emailVerified === false) {
            store.dispatch(
              loginError(
                new Error(
                  "Email not verified. Please verify your email to login."
                )
              )
            );
            store.dispatch(logoutUser());
            signOutFirebase();
            return;
          }
        });
      });
  } catch (error) {
    error.message = "Invalid email or password.Please try again";
    store.dispatch(loginError(error));
    store.dispatch(logoutUser());
  }
}

export function signOutFirebase() {
  firebase.auth().signOut();
}

export async function registerInFirebase(creds) {
  store.dispatch(registerUser());
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password);
    await result.user.updateProfile({
      displayName: `firstName:${creds.firstName} lastName:${creds.lastName}`,
    });
    return result;
  } catch (error)
  {
    
    store.dispatch(registerError(error));
    return error;
  }
}
export async function emailVerification(result) {
   await sendEmailVerification(result.user)
     .then(() => {
       console.log(
         "Signup successful! Please check your email for verification."
       );
     })
     .catch(error => {
       console.error("Error sending verification email:", error);
     });
}
export async function updateUserPassword(creds) {
  const user = firebase.auth().currentUser;

  try {
    user.updatePassword(creds.newPassword);
    store.dispatch(resetSuccess());
  } catch (e) {
    console.log(error);
    store.dispatch(resetError(error));
  }
}

export function forgotPassword(creds) {
  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: process.env.REACT_APP_URL + "/login",
    handleCodeInApp: false,
  };
  return firebase
    .auth()
    .sendPasswordResetEmail(creds.email, actionCodeSettings);
}

