import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";

import "react-drawer/lib/react-drawer.css";
import { connect } from "react-redux";

// Reactstrap

// Import menuDropdown
import ProfileMenu from "../Common/TopbarDropdown/ProfileMenu";
import Notifications from "../Common/TopbarDropdown/Notifications";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftMenu,
} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { fetchOrgSubscriptionInfoFromDb, fetchOrgTickerMessageFromDb } from "services/subscriptionsService";
import { dataFromSnapshot } from "services";
import moment from "moment"

const Header = memo(props => {
  const [search, setsearch] = useState(false);
  const [isHoldPointNotification, setIsHoldPointNotification] = useState(false);
  const [subscriptionDays, setSubscriptionDays] = useState("")
  const [subscriptionMsg, setSubscriptionMsg] = useState();
  const [holdPointNotificationMessage, setHoldPointNotificationMessage] = useState("");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  const { currentUserProfile } = useSelector(state => state.profile);
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  function daysUntil(dateString) {
    const targetDate = new Date(dateString);

    if (isNaN(targetDate.getTime())) {
      return "Invalid date format. Please provide a valid date string.";
    }

    const options = {
      timeZone: 'Australia/Melbourne',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('en-AU', options);
    const currentDateString = formatter.format(new Date());

    const [day, month, year] = currentDateString.split('/');
    const currentDate = new Date(`${year}-${month}-${day}`);

    const timeDifference = targetDate - currentDate;

    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (dayDifference > 0) {
      return dayDifference;
    } else if (dayDifference === 0) {
      return 0;
    } else {
      return dayDifference;
    }
  }

  const fetchMessage = async () => {
    try {

      if (currentUserProfile?.orgAccessId) {

        const tickerMsg = fetchOrgTickerMessageFromDb();
        tickerMsg.onSnapshot((snapshot) => {
          const result = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (result && result.length > 0) {
            setSubscriptionMsg(result[0].content); // Update content when the message changes
          } else {
            console.log("No existing message found.");
            setSubscriptionMsg("");
          }
        });

        const subscriptionsRef = fetchOrgSubscriptionInfoFromDb(currentUserProfile?.orgAccessId);
        subscriptionsRef.onSnapshot(snapshot => {
          const subscriptionSnap = snapshot.docs.map(doc => dataFromSnapshot(doc));
          const subscription = subscriptionSnap[0];
          let day = daysUntil(subscription.endDate);
          if (day < 8) {
            setSubscriptionDays(`${day === 0 ? 'Today' : `${day} days. `}`);
          }
          else {
            setSubscriptionDays("")
          }
        });
      }
    } catch (err) {
      console.error("Error fetching message:", err);
    }
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        {subscriptionDays && subscriptionMsg &&
          <div className="top-bar-banner">
            {subscriptionMsg} {subscriptionDays}
          </div>
        }
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              {/* <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button> */}
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <Notifications
              setIsHoldPointNotification={setIsHoldPointNotification}
              setHoldPointNotificationMessage={setHoldPointNotificationMessage}
            />
            <ProfileMenu />
            {isHoldPointNotification && (
              <SweetAlert
                title={""}
                showCancel={false}
                confirmBtnText="Ok"
                confirmBtnBsStyle="warning"
                focusCancelBtn
                onConfirm={() => {
                  setIsHoldPointNotification(false);
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {holdPointNotificationMessage}
                </p>
              </SweetAlert>
            )}
          </div>
        </div>

      </header>

    </React.Fragment>
  );
});

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftMenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftMenu,
  changeSidebarType,
})(withTranslation()(Header));
