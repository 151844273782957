import { documentCategory } from "common/data/documents";
import cuid from "cuid";
import { useEffect, useState } from "react";
import { TabContent, TabPane } from "reactstrap";

import { buildingClassDataMap } from "../../../common/data/clases";
import BuildingClass from "./BuildingClass";
import CheckList from "./checklist/ChecklistNew";
import ClientDetails from "./ClientDetails";
import Finalize from "./Finalize";
import FloorContainer from "./FloorContainer";
import Installer from "./Installer";
import JobCategory from "./JobCategory";
import "./styles.css";
import { APPROVED, INSPECTION_DB_NAME } from "common/constants";
import { getUsersDetails } from "services";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { updateInspectionJobDraft } from "services/inspectionService";
import { STEP, getLastStepOfWizard } from "./utils/inspectionJobWizard";
import LinkJobToInspection from "./jobLinkage/LinkJobToInspection";

export const initJob = {
  id: cuid(),
  client: {
    displayName: null,
    email: null,
    mobile: null,
    address: null,
  },
  jobType: { internal: false, external: false, belowground: false },
  jobCategory: { internal: "none", external: "none", belowground: "none" },
  buildingClass: {
    internal: buildingClassDataMap,
    external: buildingClassDataMap,
    belowground: buildingClassDataMap,
  },
  participants: [],
  rooms: {
    internal: [],
    external: [],
    belowground: [],
  },
  checklist: {},
  documents: documentCategory,
};

export default function InspectionJobWizard({
  draft,
  setActiveStep,
  isCloned,
}) {
  const lastStep = isCloned ? STEP.CLIENTDETAILS : getLastStepOfWizard(draft);
  const [activeTab, setActiveTab] = useState(lastStep);
  const [newJob, setNewJob] = useState();
  const [isDirty, setDirty] = useState(false);
  const [isWizardExited, setIsWizardExited] = useState(false);
  const [showUserValidationAlert, setShowUserValidationAlert] = useState(false);
  const [pendingOrDeletedUsers, setPendingOrDeletedUsers] = useState([]);
  const { currentUserProfile } = useSelector(state => state.profile);
  const {jobs} = useSelector(state =>state.job);
  const startJob = {
    ...initJob,
    id: cuid(),
  };
  useEffect(() => {
    setActiveTab(lastStep);
  }, [lastStep]);

  useEffect(() => {
    if (draft) {
      setNewJob(draft);
    } else {
      setNewJob(startJob);
    }
  }, [draft]);

  useEffect(() => {
    setActiveStep(activeTab);
  }, [activeTab]);

  const onPrev = () => {
    setDirty(false);
    setActiveTab(activeTab - 1);
  };

  const onNext = () => {
    if (activeTab <= STEP.FINAL) {
      let newTab = activeTab + 1;
      setActiveTab(newTab);
    }
  };

  const validateParticipants = async participants => {
    if (participants && participants.length > 0) {
      const participantsIds = participants
        // skipping invited users as we allow to add pending users as invited users
        ?.filter(user => !user.invited)
        .map(user => user.id);
      const participantsDetails = await getUsersDetails(participantsIds);
      const pendingOrDeletedUserIds = participantsDetails
        ?.filter(
          user =>
            user.orgStatus !== APPROVED ||
            user.orgAccessId !== currentUserProfile?.orgAccessId
        )
        .map(user => user.id);
      if (pendingOrDeletedUserIds && pendingOrDeletedUserIds.length > 0) {
        setShowUserValidationAlert(true);
        setPendingOrDeletedUsers(pendingOrDeletedUserIds);
      }
    }
  };

  const removePendingUsers = () => {
    if (pendingOrDeletedUsers.length > 0) {
      const updatedParticipants = newJob.participants.filter(
        user => !pendingOrDeletedUsers.includes(user.id)
      );
      draft.participants = updatedParticipants;
      newJob.participants = updatedParticipants;
      setNewJob(newJob);

      updateInspectionJobDraft(newJob.id, {
        participants: updatedParticipants,
      });
      return;
    }
  };

  return (
    <>
      {newJob && (
        <div
          className="content clearfix"
          style={{ margin: "0px -8px", padding: "0px" }}
        >
          <TabContent activeTab={activeTab} className="body">
            <TabPane tabId={STEP.CLIENTDETAILS}>
              {activeTab === STEP.CLIENTDETAILS && (
                <ClientDetails
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  isCloned={isCloned}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>

            <TabPane tabId={STEP.JOBCATEGORY}>
              {activeTab === STEP.JOBCATEGORY && (
                <JobCategory
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>

            <TabPane tabId={STEP.BUILDINGCLASS}>
              {activeTab === STEP.BUILDINGCLASS && (
                <BuildingClass
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>

            <TabPane tabId={STEP.INSTALLER}>
              {activeTab === STEP.INSTALLER && (
                <Installer
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  validateParticipants={validateParticipants}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>

            <TabPane tabId={STEP.ROOMS}>
              {activeTab === STEP.ROOMS && (
                <FloorContainer
                  switchParent={true}
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  validateParticipants={validateParticipants}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>

            <TabPane tabId={STEP.CHECKLIST}>
              {activeTab === STEP.CHECKLIST && (
                <CheckList
                  switchParent={true}
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  validateParticipants={validateParticipants}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>
            <TabPane tabId={STEP.LINKTOJOB}>
              {activeTab === STEP.LINKTOJOB && (
                <LinkJobToInspection
                  switchParent={true}
                  newJob={newJob}
                  setNewJob={setNewJob}
                  next={onNext}
                  prev={onPrev}
                  setDirty={setDirty}
                  isDirty={isDirty}
                  setIsWizardExited={setIsWizardExited}
                  isWizardExited={isWizardExited}
                  validateParticipants={validateParticipants}
                  collectionName={INSPECTION_DB_NAME}
                />
              )}
            </TabPane>
            <TabPane tabId={STEP.FINAL}>
              {activeTab === STEP.FINAL && (
                <Finalize
                  newJob={newJob}
                  prev={onPrev}
                  isDirty={isDirty}
                  activeTab={activeTab}
                  isCloned={isCloned}
                  collectionName={INSPECTION_DB_NAME}
                  validateParticipants={validateParticipants}

                />
              )}
            </TabPane>
          </TabContent>
          {showUserValidationAlert && (
            <SweetAlert
              title="Some of the Participants are currently unavailable!"
              warning
              showCancel
              closeOnClickOutside={false}
              onClickOutside={false}
              cancelBtnText="Edit Participants"
              confirmBtnText="Continue"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="primary"
              onConfirm={() => {
                removePendingUsers();
                setShowUserValidationAlert(false);
              }}
              onCancel={() => {
                removePendingUsers();
                setActiveTab(4);
                setShowUserValidationAlert(false);
              }}
            >
              Users who are Deleted or Pending Access will be removed from the
              Job!
            </SweetAlert>
          )}
        </div>
      )}
    </>
  );
}
