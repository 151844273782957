import React from "react";
import firebase from "config/firebase";
import cuid from "cuid";
import { map } from "lodash";
import { Fragment, useEffect, useState, useRef } from "react";
import Editable from "react-bootstrap-editable";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { deleteImageFromFirebaseStoreByUrl, updateJobInDb } from "services";
import { updateDocumentNotes } from "services/jobService";

import pdfLogo from "assets/images/pdflogo.png";
import wordLogo from "assets/images/docxLogo.png";
import csvLogo from "assets/images/csv.png";
import { documentCategory } from "common/data/documents";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import PDFModal from "./rooms/PdfModal";
import ProgressLine from "components/ProgressLine";

import {
  getItemStyle,
  getListStyle,
  Reorder,
} from "common/checklistUtils/dndStyleUtil";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";

// Images
import crossMark from "assets/images/icons8/icons8-delete-50.png";
import dragReorder from "assets/images/icons8/icons8-drag-reorder-50.png";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { fetchJobFromDbById } from "services/inspectionService";
import { DownloadImage } from "./rooms/DownloadImage";
import {
  fetchDocuments,
  fetchDocumentsError,
  fetchDocumentsSuccess,
} from "store/actions";
import { useDispatch } from "react-redux";
import useFirestoreCollection from "common/hooks/useFirestoreCollection";
import classNames from "classnames";
import { fetchDocumentListsFromDb } from "services/documentService";
import { Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import ImageEditor from "./ImageEditor";
import { getDownloadURL } from "common/utils";
import {
  RESIZED_IMAGES_DIMENSIONS,
  RESIZED_IMAGES_PATH,
} from "common/constants";
//Lightbox
import Lightbox from "react-18-image-lightbox";
import "react-image-lightbox/style.css";
import DownloadIcon from "@mui/icons-material/Download";
import { userName } from "pages/platformManagement/org-creation/orgService";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationPromptWithCheckBoxAndLoading from "components/Common/DeleteConfirmationPromptWIthCheckBoxAndLoading";
import WordModal from "./rooms/WordModal";
import CSVModal from "./rooms/CSVModal";

export default function AttachedFiles({
  job,
  canUpload,
  canEdit,
  collectionName,
}) {
  const [checked, setChecked] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedAttachId, setSelectedAttachId] = useState(null);
  const [documents, setDocuments] = useState(job.documents);

  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setDynamicDescription] = useState("");
  const [isGallery, setGallery] = useState(false);
  const [isPdf, setPdf] = useState({
    isPDF: false,
    isDoc: false,
    isCSV: false
  });
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const db = firebase.firestore();
  const storageRef = firebase.storage().ref();
  const documentLibraryList = useSelector(state => state.documents);
  const [documentLibrary, setDocumentLibrary] = useState(
    documentLibraryList?.documents
  );
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector(state => state.profile);
  const [checklistSearch, setChecklistSearch] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showUploadIcon, setShowUploadIcon] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectDocument, setSelectDocument] = useState(null);
  const imageRef = useRef(null);
  const [percent, setPercent] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [showDeletedCheckBox, setShowDeletedCheckBox] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = useCallback(() => {
    const newDocumentLibrary = [...documentLibrary];
    newDocumentLibrary.map(document => {
      document?.attachments?.map(doc => {
        doc.checked = false;
      });
    });
    setDocumentLibrary(newDocumentLibrary);
    setShowCheckBox(false);
    setShowUploadIcon(true);
    setSelectedDocuments([]);
  }, [
    documentLibraryList,
    documentLibrary,
    showCheckBox,
    showUploadIcon,
    selectedDocuments,
  ]);

  useFirestoreCollection({
    query: () => fetchDocumentListsFromDb(currentUserProfile?.orgAccessId),
    onStart: () => dispatch(fetchDocuments()),
    onSuccess: docs => dispatch(fetchDocumentsSuccess(docs)),
    onError: error => dispatch(fetchDocumentsError(error)),
    deps: [dispatch],
  });

  const closeModal = () => {
    setPdf({
      isPDF: false,
      isDoc: false,
      isCSV: false
    });
  };
  const getOptions = () => {
    let newOptions = [];
    documentCategory.forEach(item => {
      newOptions.push({ ...item, id: cuid() });
    });
    return newOptions;
  };
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        percent: 0,
        downloadUrl: "",
        id: "",
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    let newChecked = {};
    job.documents?.forEach(doc => {
      newChecked[doc.id] = false;
    });
    setChecked(newChecked);
    setDocumentLibrary(documentLibraryList?.documents);
  }, []);

  const onClicked = id => {
    let newChecked = { ...checked };
    newChecked[id] = !checked[id];
    setChecked(newChecked);
  };

  useEffect(() => {
    let newList = documentLibraryList?.documents?.filter(document => {
      const title = document.name;
      return title.toLowerCase().includes(checklistSearch.toLowerCase());
    });
    newList?.sort((a, b) => {
      const aStr = a.name;
      const bStr = b.name;
      return aStr.localeCompare(bStr);
    });
    setDocumentLibrary([...newList]);
  }, [checklistSearch, documentLibraryList]);

  const getNotesArray = (notesData) => {
    if (!notesData) {
      return [];
    }
    if (typeof notesData === "string") {
      return [{ noteContent: notesData, createdAt: new Date().toISOString(), createdBy: currentUserProfile?.displayName }]
    }
    if (typeof notesData === undefined) {
      return []
    }
    if (Array.isArray(notesData)) {
      return notesData
    }

    return [];
  }

  const [addNewNote, setAddNewNote] = useState()
  const updateNotes = async (docId, newNoteContent, noteIndex = null) => {
    const updatedDocuments = job.documents.map(doc => {
      if (doc.id === docId) {
        let updatedNotes = [];

        if (Array.isArray(doc.notes)) {
          updatedNotes = [...doc.notes];

          if (noteIndex !== null && updatedNotes[noteIndex]) {
            // Edit existing note
            updatedNotes[noteIndex].noteContent = newNoteContent;
            updatedNotes[noteIndex].updatedAt = new Date().toISOString();
            updatedNotes[noteIndex].createdBy = `${currentUserProfile?.firstName?.charAt(0).toUpperCase()}${currentUserProfile?.firstName?.slice(1).toLowerCase()} ` +
              `${currentUserProfile?.lastName?.charAt(0).toUpperCase()}${currentUserProfile?.lastName?.slice(1).toLowerCase()}`;
            ;
          } else {
            // Add a new note
            updatedNotes.push({
              noteContent: newNoteContent, createdAt: new Date().toISOString(), createdBy: `${currentUserProfile?.firstName?.charAt(0).toUpperCase()}${currentUserProfile?.firstName?.slice(1).toLowerCase()} ` +
                `${currentUserProfile?.lastName?.charAt(0).toUpperCase()}${currentUserProfile?.lastName?.slice(1).toLowerCase()}`
            });
          }
        } else {
          updatedNotes = [{
            noteContent: newNoteContent, createdAt: new Date().toISOString(), createdBy: `${currentUserProfile?.firstName?.charAt(0).toUpperCase()}${currentUserProfile?.firstName?.slice(1).toLowerCase()} ` +
              `${currentUserProfile?.lastName?.charAt(0).toUpperCase()}${currentUserProfile?.lastName?.slice(1).toLowerCase()}`
          }];
        }

        return { ...doc, notes: updatedNotes };
      }
      return doc;
    });


    await updateDocumentNotes(job.id, updatedDocuments, collectionName);
    setDocuments(updatedDocuments);
  };

  const onDeletedAttachment = async (docType, fileId, attachmentId, collectionName) => {
    try {
      await db.runTransaction(async txn => {
        const jobRef = fetchJobFromDbById(collectionName, job.id);
        const doc = await txn.get(jobRef);
        const jobData = doc.data();
        let attachmentUrl;
        let attachmentToDelete;
        job.documents = jobData.documents.map(file => {
          if (file.id === fileId) {
            file.attachments = file.attachments.filter(attachment => {
              if (attachment.id === attachmentId) {
                attachmentUrl = attachment.url;
                attachmentToDelete = attachment;
              }
              return attachment.id !== attachmentId;
            });
            return file;
          }
          return file;
        });

        const documentUpdate = {
          documents: job.documents,
        };
        txn.update(jobRef, documentUpdate);
        deleteImageFromFirebaseStoreByUrl(attachmentUrl);
        setDocuments(job.documents);
        if (docType.includes("image")) {
          const resizedImageUrl = await getDownloadURL(attachmentToDelete.resizedImagePath);
          await deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
        }
      });
    } catch (error) {
      console.log("Failed to delete documents", error);
    }
  };

  const onRemoveDocument = id => {
    setDocuments([...documents.filter(item => item.id !== id)]);
  };

  const onAddDocument = doc => {
    setDocuments([doc, ...documents]);
  };

  const onModalClose = () => {
    setShowEditModal(false);
    setDocuments(job.documents);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      const docSorted = Reorder(documents, sourceIndex, destIndex);
      setDocuments(docSorted);
    }
  };
  const onDrop = useCallback(acceptedFiles => {
    setselectedFiles([]);
    handleAcceptedFiles(acceptedFiles);
  });
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [".jpeg", ".jpg", ".JPEG", ".JPG"],
        "image/png": [".png", ".PNG"],
        "application/pdf": [".pdf", ".PDF"],
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        "application/msword": [".doc"],
      },
    });
  const onChangeText = (text, id) => {
    const newDocs = documents.map(item => {
      if (item.id === id) {
        const newItem = { ...item, content: text };
        return newItem;
      }
      return item;
    });
    setDocuments(newDocs);
  };

  const onSaveChanges = () => {
    job.documents = documents.map(doc => {
      let latestDoc = doc;
      const exisitingDoc = job.documents.filter(jobDoc => jobDoc.id === doc.id);
      if (exisitingDoc.length > 0) {
        latestDoc = { ...exisitingDoc[0], content: latestDoc.content };
      }
      return latestDoc;
    });
    updateJobInDb(job, collectionName);
  };
  const downloadImage = DownloadImage();

  const getBlobFromFirebase = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error downloading file: " + error);
      throw error;
    }
  };

  const addAttachmentDetails = async ({ files, id, job }) => {
    try {
      await Promise.all(
        files?.map(file => {
          return new Promise(async resolve => {
            let fileRef = storageRef.child(
              `${job?.orgId}/documents/${file?.id}`
            );
            const blob = await getBlobFromFirebase(file?.url);
            const fileId = cuid();
            file.id = fileId;
            const filePath = `${job?.orgId}/${job.jobNo}/documents/${fileId}`;
            const uploadTask = storageRef.child(filePath).put(blob, {
              contentType: file.type,
            });
            uploadTask.on(
              "state_changed",
              snap => {
                const percentUploaded = Math.round(
                  (snap.bytesTransferred / snap.totalBytes) * 100
                );
                file.percent = percentUploaded;
                setPercent(percentUploaded);
              },
              err => {
                console.error(err);
              },
              () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async downloadUrl => {
                    file.downloadUrl = downloadUrl;
                    resolve(downloadUrl);
                  })
                  .catch(err => {
                    console.error(err);
                  });
              }
            );
          });
        })
      );

      await db.runTransaction(async txn => {
        const jobRef = fetchJobFromDbById(collectionName, job.id);
        const doc = await txn.get(jobRef);
        const jobData = doc.data();
        const documents = jobData.documents;
        const updatedDocuments = documents.map(doc => {
          if (doc.id === id) {
            files?.map(file => {
              const resizedImagePath = `${job?.orgId}/${job.jobNo}/documents/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
              if (doc.attachments) {
                doc.attachments.push({
                  id: file.id,
                  userId: currentUserProfile.id,
                  name: file.name,
                  type: file.type,
                  url: file.downloadUrl,
                  size: file.size,
                  resizedImagePath: resizedImagePath,
                  date: new Date(),
                  userName: userName(currentUserProfile),
                  userId: currentUserProfile?.id
                });
              } else {
                doc.attachments = [
                  {
                    id: file.id,
                    name: file.name,
                    userId: currentUserProfile.id,
                    type: file.type,
                    url: file.downloadUrl,
                    size: file.size,
                    resizedImagePath: resizedImagePath,
                    date: new Date(),
                    userName: userName(currentUserProfile),
                    userId: currentUserProfile?.id
                  },
                ];
              }
            });
            return doc;
          }
          return doc;
        });

        job.documents = updatedDocuments;
        const documentUpdate = { documents: updatedDocuments };
        txn.update(jobRef, documentUpdate);
        setShowUploadIcon(true);
        toast.success("Document added successfully !", {
          autoClose: 2000,
        });
      });
    } catch (error) {
      console.log("Failed to add job document attachments details", error);
      toast.error("Failed to add job document attachments details", {
        autoClose: 2000,
      });
    }
  };

  const handleDeleteAllAttachments = async () => {
    if (!checkedBoxes || checkedBoxes.length === 0) {
      return;
    }
    try {
      setIsLoading(true);
      await Promise.all(
        checkedBoxes?.map(async attachmentToBeDelete => {
          await db.runTransaction(async txn => {
            const jobRef = fetchJobFromDbById(collectionName, job.id);
            const doc = await txn.get(jobRef);
            const jobData = doc.data();
            let attachmentUrl;
            let attachmentToDelete;

            job.documents = jobData?.documents?.map(file => {
              if (file.id === selectedFileId) {
                file.attachments = file.attachments.filter(attachment => {
                  if (attachment.id === attachmentToBeDelete?.id) {
                    attachmentUrl = attachment.url;
                    attachmentToDelete = attachment;
                  }
                  return attachment.id !== attachmentToBeDelete?.id;
                });
              }
              return file;
            });

            const documentUpdate = {
              documents: job.documents,
            };

            txn.update(jobRef, documentUpdate);

            try {
              // Delete the original image
              await deleteImageFromFirebaseStoreByUrl(attachmentUrl);

              if (attachmentToBeDelete?.type?.includes("image")) {
                // Delete the resized image
                const resizedImageUrl = await getDownloadURL(
                  attachmentToDelete?.resizedImagePath
                );
                await deleteImageFromFirebaseStoreByUrl(resizedImageUrl);
              }
            } catch (error) {
              console.error("Error deleting original image:", error);
            }
          });
        })
      );

      toast.success("The documents are deleted!", {
        autoClose: 2000,
      });
      setDocuments(job.documents);
      setCheckedBoxes([]);
      setShowDeleteAlert(false);
      setShowDeletedCheckBox(false);
      setIsLoading(false);
    } catch (error) {
      setShowDeleteAlert(false);
      setIsLoading(false);
      console.error("Error deleting clients:", error);
    }
  };

  const DisplayDocumentList = ({ document, index, documentId }) => {
    return (
      <div key={index} className="w-100">
        <Row className="align-items-center">
          <Col sm="6" className="d-flex align-items-center text-align-center">
            {showCheckBox && (
              <Checkbox
                className="checked"
                checked={document?.checked}
                style={{
                  float: "left",
                  height: "30px",
                  marginRight: "5px",
                  color: "#65a2eb",
                }}
                onChange={() => {
                  const newChecklist = [...documentLibrary];
                  newChecklist.map(item => {
                    if (item.id === documentId) {
                      item?.attachments?.map(doc => {
                        if (doc.id === document?.id) {
                          doc.checked = !doc.checked;
                          if (doc?.checked) {
                            setSelectedDocuments([
                              ...selectedDocuments,
                              document,
                            ]);
                          } else {
                            setSelectedDocuments(
                              selectedDocuments.filter(
                                doc => doc.id !== document.id
                              )
                            );
                          }
                        }
                      });
                    }
                  });
                  setDocumentLibrary(newChecklist);
                }}
              />
            )}

            <img
              data-dz-thumbnail=""
              className="avatar-sm rounded bg-light img-thumbnail mb-1"
              alt={document.name}
              onClick={() => {
                setImage(document.url);
                setImageName(document?.name);
                setSelectDocument(document);
                setSelectedFileId(documentId);
                setSelectedDocType(document?.type);

                if (document.type === "application/pdf") {
                  setPdf({
                    ...isPdf,
                    isPDF: true
                  });
                }
                else if (attachment.type === 'text/csv') {
                  alert("INSIDE")
                  setPdf({
                    ...isPdf,
                    isCSV: true
                  });
                }
                else if ((document.type === "application/msword") || (document.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                  setPdf({
                    ...isPdf,
                    isDoc: true
                  });
                }
                else {
                  setGallery(true);
                }
              }}
              src={document.type === "application/pdf" ? pdfLogo :
                (document.type === "application/msword") || (document.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? wordLogo :
                  document.type === "text/csv" ? csvLogo :
                    document.url}
              style={{ cursor: "pointer", fontSize: "1rem" }}
              name={document.name}
            />
            <p className="text-truncate m-1">{document?.name}</p>
          </Col>
          <Col sm="6">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                color="link"
                className="text-muted"
                onClick={() => {
                  setSelectedDocument(document);
                  addAttachmentDetails({
                    files: [document],
                    id: selectedFileId,
                    job,
                  });
                  setShowUploadIcon(false);
                }}
                disabled={!showUploadIcon}
              >
                <i className="display-6 text-muted bx bxs-cloud-upload" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const DisplayDocument = ({ document, index }) => {
    return (
      <div className="d-flex" key={index}>
        <Card
          style={{
            width: "100%",
          }}
          className="mt-1 mb-0 shadow-md border  certificate-container dz-processing dz-image-preview dz-success dz-complete"
        >
          <CardBody>
            <Row>
              <Col sm="6">
                <CardTitle style={{ cursor: "pointer", fontSize: "1rem" }}>
                  {document?.name}
                </CardTitle>
              </Col>
            </Row>
            {document?.attachments?.map((attachment, index) => (
              <DisplayDocumentList
                document={attachment}
                index={index}
                key={index}
                documentId={document.id}
              />
            ))}
          </CardBody>
        </Card>
      </div>
    );
  };

  const DisplayDocuments = ({ documentLibrary }) => {
    return documentLibrary?.map((document, index) => (
      <DisplayDocument document={document} index={index} key={index} />
    ));
  };

  const handleShowCheckBoxes = () => {
    setShowDeletedCheckBox(_showDeletedCheckBox => !_showDeletedCheckBox);
    setCheckedBoxes([]);
  };

  const handleSelectDocument = attachment => {
    const isAttachmentSelected = checkedBoxes.filter(
      doc => doc?.id === attachment?.id
    );
    if (isAttachmentSelected.length > 0) {
      setCheckedBoxes(checkedBoxes.filter(doc => doc?.id !== attachment?.id));
      return;
    }
    setCheckedBoxes([...checkedBoxes, attachment]);
    return;
  };

  return (
    <>
      {showDeleteAlert && (
        <DeleteConfirmationPromptWithCheckBoxAndLoading
          label={
            "I understand that deleting is permanent and cannot be undone."
          }
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={handleDeleteAllAttachments}
          isLoading={isLoading}
          alertLabel={"Deleting documents is in progress, Please wait.."}
        />
      )}
      {isGallery && canEdit ? (
        <ImageEditor
          imageSrc={image}
          isGallery={isGallery}
          setisGallery={setGallery}
          files={[selectDocument]}
          id={selectedFileId}
          job={job}
          collectionName={collectionName}
          imgRef={imageRef}
        />
      ) : null}

      {(isGallery && !canEdit) ? (
        <Lightbox
          mainSrc={image}
          toolbarButtons={[
            <DownloadIcon
              onClick={() => {
                const imageUrl = image;
                downloadImage(imageUrl, "image");
              }}
            />,
          ]}
          onCloseRequest={() => {
            setGallery(false);
            setImage(null);
            setImageName("");
          }}
        />
      ) : null}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            Upload Documents {" "}
            {canEdit && (
              <Button
                color="primary"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setShowEditModal(true);
                }}
              >
                Edit
              </Button>
            )}
          </CardTitle>
          <div className="table-responsive">
            <Table className="table table-nowrap table-centered table-hover mb-0">
              <tbody>
                {map(documents, (file, i) => {
                  const notesArray = getNotesArray(file?.notes);
                  return (
                    <React.Fragment key={file.id + file.content}>
                      <tr key={file.id + file.content}>
                        <td style={{ width: "45px" }}>
                          <div className="avatar-sm">
                            <span
                              className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                              onClick={() => {
                                onClicked(file.id)
                                setShowDeletedCheckBox(false);
                                setCheckedBoxes([]);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className={
                                  checked[file.id]
                                    ? "mdi mdi-folder-open text-warning"
                                    : "mdi mdi-folder text-warning"
                                }
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <h5 className="font-size-14 mb-1">
                            <span
                              className="text-dark"
                              onClick={() => onClicked(file.id)}
                            >
                              {file.content}
                            </span>
                          </h5>

                          <small className="mr-5">
                            Files : {file?.attachments?.length || 0}
                          </small>
                          {canEdit && (
                            <span className="text-center ml-5">
                              <span
                                className="text-dark"
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectedFileId(file.id);
                                  setSelectedDocType(file.type);
                                  setselectedFiles([]);
                                }}
                              >
                                <i className="bx bx-upload h3 m-0" /> Upload
                              </span>
                              {checked[file.id] && (
                                <Button
                                  color="primary"
                                  className="btn-block btn btn-danger m-1"
                                  style={{
                                    fontSize: "0.7rem",
                                    padding: "0.35rem 0.5rem",
                                  }}
                                  onClick={handleShowCheckBoxes}
                                >
                                  <i className="mdi mdi-plus-minus-outline me-1" />
                                  {showDeletedCheckBox
                                    ? "Cancel"
                                    : "Delete multiple docs"}
                                </Button>
                              )}
                              {checked[file.id] &&
                                checkedBoxes?.length > 0 &&
                                showDeletedCheckBox && (
                                  <DeleteIcon
                                    onClick={() => {
                                      setShowDeleteAlert(true);
                                    }}
                                    size={18}
                                    color="error"
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                            </span>
                          )}
                        </td>
                      </tr>
                      {checked[file.id] && (
                        <tr key={"_notes_" + i}>
                          <td style={{ width: "45px" }}>
                            <div className="avatar-sm">
                              <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                <i className="mdi mdi-text-box align-middle text-muted" />
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="notes-header">
                              <h5 className="font-size-14 mb-1">
                                <div className="text-dark">
                                  {`Notes (${file.content})`}
                                </div>
                              </h5>

                              <Button
                                color="primary"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  setAddNewNote(prev => prev === file?.id ? null : file?.id); // Trigger new note creation
                                }}
                              >
                                Add
                              </Button>
                            </div>

                            {/* Render Editable on top when addNewNote is true */}
                            {addNewNote === file?.id && (
                              <div key="new-note" style={{ marginBottom: '10px' }}>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={true}  // Editable is always active when adding a new note
                                  disabled={!canEdit}
                                  editText="Add"
                                  initialValue=""  // Empty value for a new note
                                  isValueClickable={false}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value?.trim() !== "") {
                                      // Add the new note
                                      updateNotes(file.id, value?.trim(), null);
                                      setAddNewNote(false); // Hide the input after adding
                                    }
                                  }}
                                  placement="top"
                                  showText
                                  type="textarea"
                                />
                              </div>
                            )}

                            {/* Render existing notes */}
                            {Array.isArray(notesArray) && notesArray.length > 0 && (
                              notesArray.map((note, index) => {
                                return (
                                  <div key={index} style={{ marginBottom: '10px' }}>
                                    <Editable
                                      ajax={null}
                                      alwaysEditing={false}
                                      disabled={!canEdit}
                                      editText={note ? "Edit" : "Add"}
                                      initialValue={note?.noteContent}
                                      isValueClickable={!!note?.noteContent}
                                      mode="inline"
                                      onSubmit={value => {
                                        if (value?.trim() !== "") {
                                          // Update the existing note
                                          updateNotes(file.id, value?.trim(), index);
                                        }
                                      }}
                                      placement="top"
                                      showText
                                      type="textarea"
                                    />
                                    <div className="notes-data">
                                      <small>{note?.createdBy ? note?.createdBy : "N/A"}</small>
                                      <small>{new Date(note.createdAt).toLocaleString()}</small>
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </td>
                        </tr>
                      )}



                      {checked[file.id] &&
                        file.attachments &&
                        file.attachments.map(attachment => {
                          return (
                            <tr key={"_attachment_" + attachment.id}>
                              <td
                                style={{
                                  width: showDeletedCheckBox ? "6rem" : "45px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {showDeletedCheckBox && (
                                  <div className="m-1">
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      style={{
                                        float: "left",
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        border: "2px solid #50a5f1",
                                      }}
                                      checked={
                                        checkedBoxes.filter(
                                          doc => doc?.id === attachment?.id
                                        ).length > 0
                                      }
                                      onChange={() => {
                                        setSelectedFileId(file.id);
                                        handleSelectDocument(attachment);
                                      }}
                                    />
                                  </div>
                                )}

                                <div className="avatar-sm">
                                  <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                    <img
                                      ref={imageRef}
                                      data-dz-thumbnail=""
                                      className="avatar-sm rounded bg-light"
                                      alt={attachment.name}
                                      onClick={() => {
                                        setImage(attachment.url);
                                        setImageName(attachment?.name);
                                        setSelectDocument(attachment);
                                        setSelectedFileId(file.id);
                                        setSelectedDocType(attachment.type);

                                        if (
                                          attachment.type === "application/pdf"
                                        ) {

                                          setPdf({
                                            ...isPdf,
                                            isPDF: true
                                          });

                                        }
                                        else if (attachment.type === 'text/csv') {
                                          setPdf({
                                            ...isPdf,
                                            isCSV: true
                                          });
                                        }
                                        else if ((attachment.type === "application/msword") || (attachment.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                          setPdf({
                                            ...isPdf,
                                            isDoc: true
                                          });
                                        }
                                        else {
                                          setGallery(true);
                                          if (canEdit) {
                                            toast.info("Click on image or pen icon to edit image ", {
                                              autoClose: 3000
                                            });
                                          }
                                        }
                                      }}
                                      src={
                                        attachment.type === "application/pdf"
                                          ? pdfLogo : (attachment.type === "application/msword") || (attachment.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? wordLogo :
                                            attachment.type === "text/csv" ? csvLogo :
                                              attachment.url
                                      }
                                    />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">
                                  <div className="text-dark">
                                    {attachment.name}
                                  </div>
                                </h5>
                                <small>{`Size ${attachment.size}`}</small>
                                {canEdit && !showDeletedCheckBox && <span className="text-center ml-5">
                                  <DeleteIcon
                                    onClick={() => {
                                      setConfirmAlert(true);
                                      setSelectedFileId(file.id);
                                      setSelectedDocType(attachment.type);
                                      setSelectedAttachId(attachment.id);
                                    }}
                                    size={18}
                                    color="error"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>}
                              </td>
                            </tr>
                          );
                        })}
                    </React.Fragment>
                  )
                }
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={showEditModal} size="xl">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Upload Files
          </h5>
          <button
            type="button"
            onClick={onModalClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Alert color="primary">
              <i className="mdi mdi-bullseye-arrow me-2"></i>
              List document types that need to be uploaded for the job
            </Alert>{" "}
          </Row>
          <Row>
            <Col lg="6" md="6">
              <FormGroup className="mb-3">
                <Label htmlFor="basicpill-firstname-input1">Search</Label>
                <Typeahead
                  id="client-search"
                  labelKey={"content"}
                  clearButton
                  renderMenuItemChildren={(option, { text }, index) => (
                    <Fragment>
                      <Highlighter search={text}>{option.content}</Highlighter>
                    </Fragment>
                  )}
                  onChange={selected => {
                    if (selected.length) {
                      onAddDocument({ ...selected[0], id: cuid() });
                    }
                  }}
                  onInputChange={(text, e) => { }}
                  options={getOptions()}
                  placeholder="Search..."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <DragDropContext onDragEnd={onDragEnd}>
              <div>
                <Button
                  color="link"
                  onClick={() => {
                    const newDoc = {
                      id: cuid(),
                      content: "NEW DOCUMENT TYPE",
                    };
                    onAddDocument(newDoc);
                  }}
                >
                  {"Click here to ADD new document type"}
                </Button>
              </div>
              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {documents.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <span {...provided.dragHandleProps}>
                                  <img
                                    ref={imageRef}
                                    src={dragReorder}
                                    style={{
                                      float: "right",
                                      height: "30px",
                                      width: "20px",
                                      transform: "translate(0, -5px)",
                                    }}
                                  />
                                </span>
                                <span>
                                  <img
                                    ref={imageRef}
                                    onClick={() => {
                                      onRemoveDocument(item.id);
                                    }}
                                    src={crossMark}
                                    style={{
                                      float: "right",
                                      height: "20px",
                                      marginRight: "6px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </span>

                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={item.content}
                                  isValueClickable={!!item.content}
                                  mode="inline"
                                  onSubmit={value => {
                                    if (value) {
                                      onChangeText(value, item.id);
                                    }
                                  }}
                                  onValidated={null}
                                  options={null}
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={null}
                                />
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              onSaveChanges();
              setShowEditModal(false);
            }}
            className="btn btn-success float-left"
            data-dismiss="modal"
          >
            Save
          </button>
          <button
            type="button"
            onClick={onModalClose}
            className="btn btn-secondary float-left"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        size="xl"
        className="d-flex align-items-center justify-content-center modal"
        style={{ overflow: "hidden", top: "-2rem" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Upload Documents
          </h5>
          <button
            type="button"
            onClick={() => {
              setShowModal(false);
              setSelectedFileId(null);
              setSelectedDocType(null);
              setselectedFiles([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-6 d-flex align-items-center justify-content-center">
              <Card>
                <CardBody>
                  <Form>
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        {isDragReject ? (
                          <h4>Sorry,Please upload valid image format.</h4>
                        ) : (
                          <>
                            <h4>Drop files here or click to upload.</h4>
                            <p style={{ fontSize: "12px" }}>
                              Only jpeg, jpg, png , gif & pdf formats supported.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.length > 0 && (
                        <Uploader
                          id={selectedFileId}
                          job={job}
                          files={selectedFiles}
                          collectionName={collectionName}
                        />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col
              className="col-6"
              style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
            >
              <Card>
                <CardBody>
                  <CardTitle>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: true,
                          })}
                        >
                          Saved Document Library
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardTitle>
                  <TabContent activeTab={"2"} className="p-3">
                    <TabPane className="saved-checklist-tab" tabId="2">
                      <div className="mb-2">
                        <div>
                          <Label className="saved-checklist-tab__label">
                            Search Saved Document Library
                          </Label>
                          <Button
                            size="md"
                            color="link"
                            className="saved-checklist-tab__select"
                            onClick={() => {
                              showCheckBox ? onCancel() : setShowCheckBox(true);
                              setShowUploadIcon(() => !showUploadIcon);
                            }}
                          >
                            {showCheckBox ? "Cancel" : "Select Multiple"}
                          </Button>
                          {selectedDocuments?.length > 0 && (
                            <Button
                              size="md"
                              color="link"
                              className="saved-checklist-tab__select"
                              onClick={() => {
                                addAttachmentDetails({
                                  files: selectedDocuments,
                                  id: selectedFileId,
                                  job,
                                });
                                setShowCheckBox(false);
                                setShowUploadIcon(false);
                                setSelectedDocuments([]);
                                onCancel();
                              }}
                            >
                              Upload
                            </Button>
                          )}
                          <Input
                            type="search"
                            placeholder="Enter keyword"
                            name="checklistSearch"
                            onChange={e => setChecklistSearch(e.target.value)}
                          ></Input>
                        </div>
                      </div>
                      <Row className="saved-checklist-tab__list">
                        <DisplayDocuments documentLibrary={documentLibrary} />
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {percent > 0 && (
            <ProgressLine
              percent={percent}
              setPercent={setPercent}
              files={[selectedDocument]}
            />
          )}
        </div>
      </Modal>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onDeletedAttachment(
              selectedDocType,
              selectedFileId,
              selectedAttachId,
              collectionName
            );
            setSelectedFileId(null);
            setSelectedDocType(null);
            setSelectedAttachId(null);
            setConfirmAlert(false);
            setSuccessDlg(true);
            setDynamicTitle("Deleted");
            setDynamicDescription("Your file has been deleted.");
          }}
          onCancel={() => {
            setSelectedFileId(null);
            setSelectedDocType(null);
            setSelectedAttachId(null);
            setConfirmAlert(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      {isPdf.isPDF && (
        <PDFModal
          isOpen={isPdf.isPDF}
          closeModal={closeModal}
          pdfUrl={image}
          pdfName={imageName}
          pdfJob={downloadImage}
        />
      )}
      {
        isPdf.isDoc &&
        <WordModal
          isOpen={isPdf.isDoc}
          closeModal={closeModal}
          fileUrl={image}
          pdfName={imageName}
          pdfJob={downloadImage}
        />
      }

      {
        isPdf.isCSV &&
        <CSVModal
          isOpen={isPdf.isCSV}
          closeModal={closeModal}
          fileUrl={image}
          pdfName={imageName}
          pdfJob={downloadImage}
        />
      }

    </>
  );
}

export function Uploader({ job, id, files, collectionName }) {
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);
  const { currentUserProfile } = useSelector(state => state.profile);

  const addAttachmentDetails = async () => {
    try {
      await db.runTransaction(async txn => {
        const jobRef = fetchJobFromDbById(collectionName, job.id);
        const doc = await txn.get(jobRef);
        const jobData = doc.data();
        const documents = jobData.documents;
        const updatedDocuments = documents.map(doc => {
          if (doc.id === id) {
            files?.map(file => {
              const resizedImagePath = `${job?.orgId}/${job.jobNo}/documents/${RESIZED_IMAGES_PATH}/${file.id}_${RESIZED_IMAGES_DIMENSIONS}`;
              if (doc.attachments) {
                doc.attachments.push({
                  id: file.id,
                  userId: currentUserProfile.id,
                  name: file.name,
                  type: file.type,
                  url: file.downloadUrl,
                  size: file.formattedSize,
                  resizedImagePath: resizedImagePath,
                  date: new Date(),
                  userName: userName(currentUserProfile),
                  userId: currentUserProfile?.id,
                });
              } else {
                doc.attachments = [
                  {
                    id: file.id,
                    name: file.name,
                    userId: currentUserProfile.id,
                    type: file.type,
                    url: file.downloadUrl,
                    size: file.formattedSize,
                    resizedImagePath: resizedImagePath,
                    date: new Date(),
                    userName: userName(currentUserProfile),
                    userId: currentUserProfile?.id,
                  },
                ];
              }
            });
            return doc;
          }
          return doc;
        });

        job.documents = updatedDocuments;
        const documentUpdate = { documents: updatedDocuments };
        txn.update(jobRef, documentUpdate);
        toast.success("Document added successfully !", {
          autoClose: 2000,
        });
      });
    } catch (error) {
      console.log("Failed to add job document attachments details", error);
      toast.error("Failed to add job document attachments details", {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    uploadToFirebase();
  }, []);

  const uploadToFirebase = async () => {
    await Promise.all(
      files?.map(file => {
        return new Promise(resolve => {
          const fileId = cuid();
          file.id = fileId;
          const filePath = `${job?.orgId}/${job.jobNo}/documents/${fileId}`;
          const uploadTask = storageRef.child(filePath).put(file, {
            contentType: file.type,
          });

          uploadTask.on(
            "state_changed",
            snap => {
              const percentUploaded = Math.round(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              file.percent = percentUploaded;
              setPercent(percentUploaded);
            },
            err => {
              console.error(err);
              setError(err);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(async downloadUrl => {
                  file.downloadUrl = downloadUrl;
                  resolve(downloadUrl);
                })
                .catch(err => {
                  console.error(err);
                  setError(err);
                });
            }
          );
        });
      })
    );
    addAttachmentDetails();
  };

  const Message = ({ children }) => {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setPercent(-1);
        setShow(false);
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
      return null;
    }

    // If show is true this will be returned
    return <>{children}</>;
  };

  const FileInfo = () => {
    return files.map((file, i) => {
      return (
        <div key={file.id + i.toString()}>
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={file.name}
                    src={
                      file.type === "application/pdf" ? pdfLogo :
                        (file.type === "application/msword") || (file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? wordLogo :
                          file.type === "text/csv" ? csvLogo :
                            file.preview
                    }
                  />
                </Col>
                <Col>
                  <div to="#" className="text-muted font-weight-bold">
                    {file.name}
                  </div>
                  <p className="mb-0">
                    <strong>{file.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="p-1">
              {file.percent === 100 ? (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="success"
                  value={file.percent}
                />
              ) : (
                <Progress
                  className="progress-sm"
                  striped
                  animated
                  color="danger"
                  value={file.percent}
                />
              )}
            </div>
          </Card>
        </div>
      );
    });
  };

  if (percent === -1) {
    return null;
  } else if (percent === 100) {
    return (
      <Message>
        <FileInfo />
      </Message>
    );
  } else {
    return <FileInfo />;
  }
}
