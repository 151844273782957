import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress} from "react-places-autocomplete";
import { Input, Label } from "reactstrap";

export default function AdressAutoComplete({
  address,
  setAddress,
  onSaveClickHandler,
  initialAddressValue,
  setEditAddress,
  label = false,
  labelName = "Address",
  labelComponent = null,
  buttons = true,
}) {
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
  const [postalCodes, setPostalCodes] = useState({});
  const handleSelect = async (addr) => {
    try {
      // Geocode the selected address
      const results = await geocodeByAddress(addr);
      const addressComponents = results[0].address_components;

      // Extract postal code from address components
      const postalCodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      const postalCode = postalCodeComponent ? postalCodeComponent.long_name : "";
      // Append postal code to the description
      const fullAddressWithPostalCode = `${addr} ${postalCode ? ", " +postalCode : ""}`;
      setAddress(fullAddressWithPostalCode);
    } catch (error) {
      console.error("Error fetching postal code:", error);
      setAddress(addr); // Fall back to the original address if there's an error
    }
  };

  const fetchPostalCode = async (suggestion) => {
    if (postalCodes[suggestion.placeId]) return;

    try {
      const results = await geocodeByAddress(suggestion.description);
      const addressComponents = results[0].address_components;
      const postalCodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      const postalCode = postalCodeComponent ? postalCodeComponent.long_name : "";
      setPostalCodes((prev) => ({
        ...prev,
        [suggestion.placeId]: postalCode,
      }));
    } catch (error) {
      console.error("Error fetching postal code for suggestion:", error);
    }
  };

  const sortPlaces = (a, b) => {
    if (a.description.includes("Australia") && !b.description.includes("Australia")) {
      return -1;
    } else if (!a.description.includes("Australia") && b.description.includes("Australia")) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "280px",
      }}
    >
      <PlacesAutocomplete
        value={address}
        onChange={(addr) => setAddress(addr)}
        onSelect={handleSelect}
      >
       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {label && labelName ? <Label>{labelName}</Label> : labelComponent}
            <Input
              className={label ? "addressWithLabel" : "addressInput"}
              {...getInputProps({
                placeholder: "Search address ...",
              })}
            />
            {label && !address && <small className="requiredError"></small>}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const isActive = hoveredSuggestion?.placeId === suggestion.placeId;
                const postalCode = postalCodes[suggestion.placeId] || "";
                
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: isActive
                        ? "suggestion-item--active"
                        : "suggestion-item",
                      style: {
                        backgroundColor: isActive ? "#fafafa" : "#ffffff",
                        cursor: "pointer",
                      },
                      onMouseEnter: () => {
                        setHoveredSuggestion(suggestion);
                        fetchPostalCode(suggestion);
                      },
                      onMouseLeave: () => setHoveredSuggestion(null),
                    })}
                  >
                    <span>
                      {suggestion.description}
                      {postalCode && `, ${postalCode}`}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {buttons && (
        <div className="action-buttons">
          <CheckOutlinedIcon
            className="saveOrCancelButton"
            style={{
              backgroundColor: "#34c38f",
            }}
            onClick={onSaveClickHandler}
          />

          <CloseIcon
            style={{
              backgroundColor: "#f46a6a",
            }}
            className="saveOrCancelButton"
            onClick={() => {
              initialAddressValue?.length < 1
                ? setAddress("")
                : setAddress(initialAddressValue);

              setEditAddress(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

AdressAutoComplete.propTypes = {
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  onSaveClickHandler: PropTypes.func,
  setEditAddress: PropTypes.func,
  initialAddressValue: PropTypes.string,
  label: PropTypes.bool,
  buttons: PropTypes.bool,
};

