import { toast } from "react-toastify";
import {
  getOrgFromDb,
  addJobParticipants,
  updateJobParticipant,
  addAdminNotificationsToDb,
  sendRoleChangeEmailNotificationToUser,
  dataFromSnapshot,
  getUser,
} from "services";
import {
  ADD_EXISTING_USER,
  ORGANISATION_OWNER,
  EXISTING_USER_INVITE,
  ROLE_CHANGE,
  REMOVE_USER,
  ADD_INVITED_USER,
  ACCESS_CHANGE,
  INSPECTION_DB_NAME,
  INVITED_USER_INVITE,
} from "../../../common/constants";
import { sendEmailToInvitedUserOfJob } from "../jobCreation/utils/user";
import cuid from "cuid";
import firebase from "config/firebase";
const getActionType = notificationType => {
  if (
    notificationType === ADD_EXISTING_USER ||
    notificationType === ADD_INVITED_USER
  ) {
    return "added-to-job";
  }
  if (notificationType === REMOVE_USER) {
    return "removed-from-job";
  }
  if (notificationType === ROLE_CHANGE) {
    return "role-change";
  }
  if (notificationType === ACCESS_CHANGE) {
    return "access-change";
  }
};
const getNotificationDescription = (
  job,
  notificationType,
  orgName,
  participant
) => {
  if (notificationType === ADD_EXISTING_USER) {
    return `You have been added to Job ${job.jobNo} belonging to ${orgName} as a ${participant.role.label}.`;
  }
  if (notificationType === ADD_INVITED_USER) {
    return `You have been invited to Job ${job.jobNo} belonging to ${orgName} as a ${participant.role.label}.`;
  }
  if (notificationType === REMOVE_USER) {
    return `You have been removed from Job ${job.jobNo} belonging to ${orgName}.`;
  }
  if (notificationType === ROLE_CHANGE) {
    return `Admin has changed your role to ${participant.role.label} of the Job ${job.jobNo} belonging to ${orgName}.`;
  }
  if (notificationType === ACCESS_CHANGE) {
    return "access-change";
  }
};
export async function notificationService(job, collectionName, notificationType,orgName,participant) {
    try {
      const db = firebase.firestore();
      const actionType = getActionType(notificationType);
      const description = getNotificationDescription(
        job,
        notificationType,
        orgName,
        participant
      );
      const notification = {
        id: cuid(),
        jobId: job.id,
        title: job.jobNo,
        isRead: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        type: "admin",
        description,
        recipientId: participant.id,
        actionType,
      };
      if (collectionName === INSPECTION_DB_NAME) {
        notification.isInspectionJob = true;
      }
      await addAdminNotificationsToDb([notification]);
    } catch (err) {
      console.error("notification", err);
    }
  };
export function existingUser(
  participants,
  selectedUser,
  searchRef,
  setSelectedUser,
  role,
  access,
  setParticipants,
  job,
  collectionName,
  currentUserProfile
) {

  return async () => {
    const participantIds = participants.map(user => user.id);
    if (!selectedUser || !selectedUser.length) {
      return;
    }
    const userToAdd = selectedUser[0];
    if (participants.some(user => user.email === userToAdd.email)) {
      toast.warning("The user is already a participant of this job", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      setSelectedUser(null);
      return;
    }
    const newParticipant = {
      displayName: selectedUser[0].displayName || null,
      firstName: selectedUser[0].firstName || null,
      lastName: selectedUser[0].lastName || null,
      email: selectedUser[0].email,
      mobile: selectedUser[0].mobile || null,
      id: selectedUser[0].id,
      type: selectedUser[0].type,
      registered: true,
      invited: false,
      isNewlyAdded: true,
      isEmailSent: participantIds.includes(selectedUser[0].id),
      role: (selectedUser[0].type === ORGANISATION_OWNER && {
        label: "Org Owner",
        value: "Org Owner",
      }) ||
        role || {
          label: "Installer",
          value: "installer",
        },
      access: (selectedUser[0].type === ORGANISATION_OWNER && {
        label: "Full",
        value: "full",
      }) ||
        access || { label: "Full", value: "full" },
    };
    setParticipants(participants => [...participants, newParticipant]);
    searchRef.current.clear();
    setSelectedUser(null);
    try {
      await addJobParticipants(job.id, newParticipant, collectionName);
      toast.success("Added the user successfully", {
        autoClose: 2000,
      });
      const snapshot = await getOrgFromDb(job.orgId);
      const { name: orgName } = snapshot.data();
      await notificationService(job,collectionName,ADD_EXISTING_USER, orgName, newParticipant);
      await sendEmailToInvitedUserOfJob(
        orgName,
        {
          email: newParticipant.email,
          role: newParticipant.role.label,
          password: null,
        },
        {
          displayName: currentUserProfile.displayName,
          email: currentUserProfile.email,
        },
        {
          id: job.id,
          jobNo: job.jobNo,
          email: job.client.email,
          jobAddress: job.jobAddress,
          phone: job.client.mobile,
          inviteType: EXISTING_USER_INVITE,
        }
      );
    } catch (err) {
      console.log(err);
      toast.error("Failed to add the user", {
        autoClose: 2000,
      });
    }
  };
}
export function roleChange(
  participants,
  job,
  collectionName,
  currentUserProfile
) {
  return async (userId, updatedData) => {
    try {
      const participant = participants.find(user => user.id === userId);
      if (!participant) {
        return;
      }
      await updateJobParticipant(job.id, userId, updatedData, collectionName);
      toast.success(`Changed the user's role successfully`, {
        autoClose: 2000,
      });
      const snapshot = await getOrgFromDb(job.orgId);
      const { name: orgName } = snapshot.data();
      if (job.accessIds.includes(participant.id)) {
        await notificationService(job,collectionName,ROLE_CHANGE, orgName, {
          ...participant,
          ...updatedData,
        });
        await sendRoleChangeEmailNotificationToUser(
          orgName,
          job.orgId,

          {
            jobNo: job.jobNo,
            jobAddress: job.jobAddress,
            phone: job.client.mobile ?? "",
            email: job.client?.email ?? "",
          },
          {
            displayName: participant.displayName,
            email: participant.email,
            previousRole: participant.previousRole,
            newRole: updatedData.role.label,
          },
          currentUserProfile
        );
      }
    } catch (err) {
      console.log("onAccessChange", err);
      toast.success(`Failed to update the user's role`, {
        autoClose: 2000,
      });
    }
  };
}
export function invitedUser(email, participants, searchRef, options, createInvitedUser, setParticipants, setEmail, setMobile, setName, job, collectionName, currentUserProfile) {
  return async () => {
    const userEmail = email.toLowerCase();
    const userSnapshot = await getUser(userEmail);

    if (participants.some(user => user.email === userEmail)) {
      toast.warning("The user is already a participant of this job", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      return;
    }
    
    const isUserPartOfTheOrg = options.some(
      user => user.email === userEmail && user.orgStatus !== "Pending"
    );
    if (isUserPartOfTheOrg) {
      toast.warning(
        "The user is part of the Organisation. Add as an Existing User",
        {
          autoClose: 4000,
        }
      );
      return;
    }
    const docs = userSnapshot.docs.map(doc => dataFromSnapshot(doc));
    const userInfo = docs && docs.length ? docs[0] : null;
    if(!userInfo?.orgAccessId){
      toast.warning("The user is not having ib license", {
        autoClose: 4000,
      });
      searchRef.current.clear();
      return;
    }
    const invitedUser = await createInvitedUser(userInfo);
    setParticipants([...participants, invitedUser[0]]);
    setEmail("");
    setMobile("");
    setName("");
    try {
      addJobParticipants(job.id, invitedUser[0], collectionName);
      toast.success("Added the invited user successfully", {
        autoClose: 2000,
      });
      const snapshot = await getOrgFromDb(job.orgId);
      const { name: orgName } = snapshot.data();
      await notificationService(job,collectionName,ADD_INVITED_USER, orgName, invitedUser[0]);
      sendEmailToInvitedUserOfJob(
        orgName,
        {
          email: invitedUser[0].email,
          role: invitedUser[0].role.label,
          password: invitedUser[1],
        },
        {
          displayName: currentUserProfile.displayName,
          email: currentUserProfile.email,
        },
        {
          id: job.id,
          jobNo: job.jobNo,
          email: job.client.email,
          jobAddress: job.jobAddress,
          phone: job.client.mobile,
          inviteType: INVITED_USER_INVITE,
        }
      );
    } catch (err) {
      console.log(err);
      toast.error("Failed to add the invited user", {
        autoClose: 2000,
      });
    }
  };
}
