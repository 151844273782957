// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requiredError {
  font-size: 10.4px;
  color: #f46a6a;
  margin-top: 4px;
}

.sui-search-box__submit {
  background: none;
  background-color: red;
}

.sui-layout-sidebar-toggle {
  color: red;
  border: 1px solid red;
}

.sui-result__title,
.sui-result__title-link {
  color: red;
}

.sui-facet-view-more {
  color: red;
}
.upload_csv {
  background-color: #4ca4ef;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  margin-right: 7px;
  cursor: pointer;
  width: 120px;
  height: 36px;
  position: relative;
}
.upload_csv input {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/common/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".requiredError {\n  font-size: 10.4px;\n  color: #f46a6a;\n  margin-top: 4px;\n}\n\n.sui-search-box__submit {\n  background: none;\n  background-color: red;\n}\n\n.sui-layout-sidebar-toggle {\n  color: red;\n  border: 1px solid red;\n}\n\n.sui-result__title,\n.sui-result__title-link {\n  color: red;\n}\n\n.sui-facet-view-more {\n  color: red;\n}\n.upload_csv {\n  background-color: #4ca4ef;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  border-radius: 5px;\n  margin-right: 7px;\n  cursor: pointer;\n  width: 120px;\n  height: 36px;\n  position: relative;\n}\n.upload_csv input {\n  opacity: 0;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
