// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.activeLabel {
  color: #65a2eb; }

.stepsContainer {
  display: flex;
  flex-direction: row; }

.step {
  width: 34px;
  height: 5px;
  margin: 8px 3px; }

.completedStep {
  background-color: #65a2eb; }

.pendingStep {
  background-color: #e4effc; }
`, "",{"version":3,"sources":["webpack://./src/components/HorizontalStepper/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB,EAAA;;AAGrB;EACE,cAAc,EAAA;;AAGhB;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,WAAW;EACX,WAAW;EACX,eAAe,EAAA;;AAGjB;EACE,yBAAyB,EAAA;;AAG3B;EACE,yBAAyB,EAAA","sourcesContent":[".headerContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.activeLabel {\n  color: #65a2eb;\n}\n\n.stepsContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.step {\n  width: 34px;\n  height: 5px;\n  margin: 8px 3px;\n}\n\n.completedStep {\n  background-color: #65a2eb;\n}\n\n.pendingStep {\n  background-color: #e4effc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
