import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import { currentUserHasFullAccess } from "common/utils";
import CircleIcon from "@mui/icons-material/Circle";
import { INTERNAL, EXTERNAL, BELOW_GROUND, AVATAR_URL } from "../../common/constants";
import Category from "../../pages/jobs/jobTypes/Category";
import { MenuType } from "./MenuType";
import { totalPercentage } from "pages/jobs/jobTypes/active/percentageCalculation";
import ProgressBar from "pages/jobstracker/HorizontalProgressBar";
import UserAvatars from "./UserAvatars";

export const ListView = ({
  result,
  job,
  setSearchTerm,
  notification,
  resultSearchTerm,
  pathname,
  setJobUpdate,
  isLinkEnabled,
  collectionName
}) => {
  const { currentUserProfile } = useSelector(state => state.profile);
  const [percentage, setPercentage] = useState(0);
  const {
    internal: isInternal,
    external: isExternal,
    belowground: isBelowground,
    report: isReport,
    other: isOther,
  } = JSON.parse(result.jobtype.raw);

  const MAX_DISPLAY_USERS = 3

  useEffect(() => {
    const calculateTotalPercentage = async () => {
      const result = await totalPercentage(job);
      setPercentage(result);
    };
    calculateTotalPercentage();
  }, [job]);
  return (
    <React.Fragment>
      <tbody>
        <tr>
          <td>
            {notification !== undefined ? (
              <CircleIcon
                className="mr-4 text-wrap font-size-14"
                style={{ fill: "#50A5F1", display: "inline-block" }}
              />
            ) : null}
            {isLinkEnabled ? (
              <h5
                className="text-wrap font-size-14"
                style={{
                  textIndent: notification !== undefined ? "15px" : "30px",
                  display: "inline-block",
                }}
              >
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {`${result?.orgidentitycode?.raw || ""} ${result.jobno.raw}`}
                </Link>
              </h5>
            ) : (
              <h5
                className="text-wrap font-size-14"
                style={{
                  textIndent: notification !== undefined ? "15px" : "30px",
                  display: "inline-block",
                }}
              >
                {
                  `${result?.orgidentitycode?.raw || ""} ${result.jobno.raw}`
                }

              </h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {job?.customjobno}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14"> {job?.customjobno}</h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.jobname?.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14"> {result.jobname?.raw}</h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.client__displayname.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14">
                {" "}
                {result.client__displayname.raw}
              </h5>
            )}
          </td>
          <td>
            {isLinkEnabled ? (
              <h5 className="text-wrap font-size-14">
                <Link
                  to={{
                    pathname: `${pathname}/${result.id.raw}`,
                    state: {
                      pageNo: location?.search,
                      query: resultSearchTerm,
                      type: pathname,
                    },
                  }}
                  className="text-dark"
                >
                  {result.jobaddress.raw}
                </Link>
              </h5>
            ) : (
              <h5 className="text-wrap font-size-14">
                {" "}
                {result.jobaddress.raw}
              </h5>
            )}
          </td>

          <td>
            <h5 className="text-wrap font-size-14">
              {isInternal && <Category type={INTERNAL} job={job} isList />}
              {isExternal && <Category type={EXTERNAL} job={job} isList />}
              {isBelowground && (
                <Category type={BELOW_GROUND} job={job} isList />
              )}
              {isReport && <Badge className={"bg-secondary"}>{"Report"}</Badge>}
              {isOther && <Badge className={"bg-danger"}>{"Other"}</Badge>}
            </h5>
          </td>

          <td>
            <UserAvatars participants={result.participants} />
          </td>

          <td>
            {currentUserHasFullAccess(job, currentUserProfile.id) && (
              <MenuType
                pathname={pathname}
                setSearchTerm={setSearchTerm}
                job={job}
                setJobUpdate={setJobUpdate}
                isLinkEnabled={isLinkEnabled}
                collectionName={collectionName}
              />
            )}
          </td>
          <td><ProgressBar percentage={percentage} /></td>
        </tr>
      </tbody>
    </React.Fragment>
  );
};