import React, { useState, useEffect } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button } from "reactstrap";
import { getTickerMessages, addTickerMessageToDb, editTickerMessage } from 'services/tickerService';
import { toast } from 'react-toastify';

const ContentManagement = () => {
    const initialMessage = "Your subscription will expire in"
    const [content, setContent] = useState(initialMessage);
    const [loading, setLoading] = useState(false); 
    const [messageId, setMessageId] = useState(null);

    const fetchMessage = async () => {
        setLoading(true);
        try {
            const result = await getTickerMessages(); 
            if (result && result.length > 0) {
                setContent(result[0].content);
                setMessageId(result[0].id);
            } else {
                console.log("No existing message found.");
            }
        } catch (err) {
            console.error("Error fetching message:", err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchMessage();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (messageId) {
                const result = await editTickerMessage(messageId, content);
                toast.success("Ticker message updated successfully!")
            } else {
                const result = await addTickerMessageToDb(content);
                setMessageId(result.id);
                toast.success("Ticker message created successfully!")
            }
        } catch (err) {
            console.error("Error submitting message:", err);
            toast.error("Failed to save message. Please try again.")
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs
                    title="Platform Management"
                    breadcrumbItem="Manage Ticker Content"
                />
                <form onSubmit={handleSubmit}>
                    <div>
                        <textarea
                            className='w-50 p-3 mh-50' placeholder='Add a content'
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            rows="4"
                            cols="50"
                            disabled={loading}
                        />
                    </div>
                    <Button color="primary"
                        className="font-16 mt-3 btn-block btn btn-primary"
                        type="submit" disabled={loading}>
                        {loading ? "Saving..." : "Save"}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ContentManagement;
