import firebase from "../config/firebase";
const db = firebase.firestore();


export async function createTickerContent(req, res) {
	cors(req, res, async () => {
		try {
			const message = req.body;
			if (!message) {
				return res.status(400).send("No message found");
			}

			const tickerMessage = await admin.auth().createTickerContent({
				tickerMessage: message
			});

			const dbResp = await db
				.collection("ticker")
				.doc(tickerMessage.uid)
				.set({
					tickerMessage: message,
				});

			if (dbResp) {
				return res.status(200).send("Ticker message created successfully");
			}
			console.log("Ticker message creation failed");
			return res.status(400).send("Ticker message creation failed");
		} catch (error) {
			console.log("Ticker message creation failed", JSON.stringify(error));
			return res.status(500).send(JSON.stringify(error));
		}
	});
};

export async function addTickerMessageToDb(content) {
  if (!content || typeof content !== "string") {
    throw new Error("Invalid content. Please provide a valid string message.");
  }

  try {
    // Create the message object to save
    const message = {
      content: content,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Add the message to the "ticker" collection
    const docRef = await db.collection("ticker").add(message);

		// Retrieve the saved message from Firestore
    const savedDoc = await docRef.get();
    const savedData = savedDoc.data();

    return {
      id: docRef.id,
      ...savedData,
    };
  } catch (error) {
    console.error("Error adding ticker message:", error);
    throw error; // Throw error for higher-level handling
  }
}

export async function getTickerMessages(messageId = null) {
  try {
    if (messageId) {
      // Fetch a specific message by its ID
      const docRef = db.collection("ticker").doc(messageId);
      const doc = await docRef.get();

      if (!doc.exists) {
        throw new Error(`Message with ID ${messageId} not found`);
      }

      return { id: doc.id, ...doc.data() };
    } else {
      // Fetch all messages
      const snapshot = await db.collection("ticker").orderBy("createdAt", "desc").get();
      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return messages;
    }
  } catch (error) {
    console.error("Error fetching ticker messages:", error);
    throw error; // Throw error for higher-level handling
  }
}

export async function editTickerMessage(messageId, updatedContent) {
  if (!messageId || !updatedContent) {
    throw new Error("Message ID and updated content are required.");
  }

  try {
    const docRef = db.collection("ticker").doc(messageId);

    // Check if the message exists
    const doc = await docRef.get();
    if (!doc.exists) {
      throw new Error(`Message with ID ${messageId} not found`);
    }

    // Update the content field
    await docRef.update({
      content: updatedContent,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return { id: messageId, content: updatedContent };
  } catch (error) {
    console.error("Error updating ticker message:", error);
    throw error; // Throw error for higher-level handling
  }
}
