// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 768px) {
  .margin-top-avatar {
    margin-top: 86px;
  }
}

.avatar-dropzone {
  min-height: 25;
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
  width: 300px;
}

.doc-dropzone {
  min-height: 25;
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
}

.saveOrCancelButton {
  color: white;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  padding: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/company/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;AACd","sourcesContent":["@media only screen and (max-width: 768px) {\n  .margin-top-avatar {\n    margin-top: 86px;\n  }\n}\n\n.avatar-dropzone {\n  min-height: 25;\n  border: 2px dashed #ced4da;\n  background: #fff;\n  border-radius: 6px;\n  width: 300px;\n}\n\n.doc-dropzone {\n  min-height: 25;\n  border: 2px dashed #ced4da;\n  background: #fff;\n  border-radius: 6px;\n}\n\n.saveOrCancelButton {\n  color: white;\n  border-radius: 3px;\n  height: 30px;\n  width: 30px;\n  padding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
