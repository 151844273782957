// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Action {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
@media (hover: hover) {
  .Action:hover {
    background-color: var(--action-background, rgba(0, 0, 0, 0.05));
  }
  .Action:hover svg {
    fill: #6f7b88;
  }
}
.Action svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}
.Action:active {
  background-color: var(--background, rgba(0, 0, 0, 0.05));
}
.Action:active svg {
  fill: var(--fill, #788491);
}
.Action:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
}
`, "",{"version":3,"sources":["webpack://./src/components/dnd/action.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,6BAA6B;EAC7B,wCAAwC;AAC1C;AACA;EACE;IACE,+DAA+D;EACjE;EACA;IACE,aAAa;EACf;AACF;AACA;EACE,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,wDAAwD;AAC1D;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,mEAAmE;AACrE","sourcesContent":[".Action {\n  display: flex;\n  width: 12px;\n  padding: 15px;\n  align-items: center;\n  justify-content: center;\n  flex: 0 0 auto;\n  touch-action: none;\n  cursor: var(--cursor, pointer);\n  border-radius: 5px;\n  border: none;\n  outline: none;\n  appearance: none;\n  background-color: transparent;\n  -webkit-tap-highlight-color: transparent;\n}\n@media (hover: hover) {\n  .Action:hover {\n    background-color: var(--action-background, rgba(0, 0, 0, 0.05));\n  }\n  .Action:hover svg {\n    fill: #6f7b88;\n  }\n}\n.Action svg {\n  flex: 0 0 auto;\n  margin: auto;\n  height: 100%;\n  overflow: visible;\n  fill: #919eab;\n}\n.Action:active {\n  background-color: var(--background, rgba(0, 0, 0, 0.05));\n}\n.Action:active svg {\n  fill: var(--fill, #788491);\n}\n.Action:focus-visible {\n  outline: none;\n  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
