import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import jobReducer from "./job/reducer";
import layoutReduer from "./layout/reducer";
import orgReducer from "./org/reducer";
import orgUsersReducer from "./orgUsers/reducer";
import personReducer from "./person/reducer";
import profileReducer from "./profile/reducer";
import setupReducer from "./setup/reduder";
import subscriptionsReducer from "./subscription/reducer";
import taskReduer from "./tasks/reducer";
import notificationsReducer from "./notifications/reducer";
import inspectionReducer from "./inspection_setup/reducer";
import notesReducer from "./notes/reducer";
import documentsReducer from "./documents/reducer";
import jobReportReducer from "./job_setup/reducer";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    layout: layoutReduer,
    auth: authReducer,
    profile: profileReducer,
    job: jobReducer,
    person: personReducer,
    org: orgReducer,
    tasks: taskReduer,
    setup: setupReducer,
    subscriptions: subscriptionsReducer,
    orgUsers: orgUsersReducer,
    notifications: notificationsReducer,
    inspection_setup:inspectionReducer,
    notes: notesReducer,
    documents: documentsReducer,
    job_setup: jobReportReducer,
  });

export default rootReducer;
