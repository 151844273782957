// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageContainer {
  margin: 0px 40px; }

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center; }

.header {
  color: #65a2eb; }

.stepperContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 22px; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobCreate.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB,EAAA;;AAGrB;EACE,cAAc,EAAA;;AAGhB;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB,EAAA","sourcesContent":[".pageContainer {\n  margin: 0px 40px;\n}\n\n.headerContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.header {\n  color: #65a2eb;\n}\n\n.stepperContainer {\n  display: flex;\n  flex-grow: 1;\n  justify-content: center;\n  margin-bottom: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
