// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px; }

.close-button {
  margin-left: 12px; }

.capitilize {
  text-transform: capitalize; }

.no-page-break {
  page-break-inside: avoid; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/documents/CertCertificate.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB,EAAA;;AAEpB;EACE,iBAAiB,EAAA;;AAEnB;EACE,0BAA0B,EAAA;;AAE5B;EACE,wBAAwB,EAAA","sourcesContent":[".download-button {\n  display: flex;\n  justify-content: flex-end;\n  margin-right: 10px;\n}\n.close-button {\n  margin-left: 12px;\n}\n.capitilize {\n  text-transform: capitalize;\n}\n.no-page-break{\n  page-break-inside: avoid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
