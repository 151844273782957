import React from "react";
import { Modal, Form } from "reactstrap";

import JobPhotoUploader from "pages/userProfile/JobPhotoUploader";
import { AVATAR_URL } from "common/constants";

export default function ParticipantDetail({ isOpen, setIsOpen, obj }) {
  
  return (
    <Modal isOpen={isOpen} className="participant-modal-content" centered>
      <div className="participant-modal">
        <div className="participant-detail-heading">
          <h3 className="mt-0" id="myModalLabel">
            Participants Detail
          </h3>
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div className="p-2 w-100">
          <div className="participant-form-card w-100">
            <Form>
              <div className="mt-2 align-items-center name-detail">
                <div style={{ width: "15%" }}>
                  <img
                    src={
                      obj?.photoURL ||
                      `${AVATAR_URL}&name=${obj?.displayName}`
                    }
                    className="rounded-circle avatar-sm"
                    alt=""
                  />
                </div>
                <div style={{ width: "85%" }}>
                  <h4 className="mb-0 mt-3 fs-5">
                    {obj?.firstName || obj?.lastName
                      ? `${obj?.firstName} ${obj?.lastName}`
                      : obj?.displayName}
                  </h4>
                  <p className="text-muted">{obj?.email}</p>
                </div>
              </div>

              <div className="mt-4 role-detail">
                <div style={{ width: "50%" }}>
                  <div className="mb-3">
                    <strong>Mobile</strong>: {obj?.mobile || "NA"}
                  </div>
                  <div className="mb-3">
                    <strong>Role</strong>:{" "}
                    <span className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                      {obj.role?.label}
                    </span>
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <div className="mb-3">
                    <strong>Access</strong>:{" "}
                    <span className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                      {obj?.access?.label}
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
