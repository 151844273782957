// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklistItem {
  width: 200px !important; }

.uploadButton {
  display: flex;
  justify-content: flex-end; }

.button-edit {
  flex: 1 1;
  width: 3rem;
  margin-right: 10px;
  /* Adjust as per your spacing preference */ }

a[href="https://markerjs.com/"] {
  display: none !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/jobDetails/rooms/checklist.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAA;;AAGzB;EACE,aAAa;EACb,yBAAyB,EAAA;;AACzB;EACA,SAAO;EACP,WAAW;EACX,kBAAkB;EAAE,0CAAA,EAA2C;;AAGjE;EACE,wBAAwB,EAAA","sourcesContent":[".checklistItem {\n  width: 200px !important;\n}\n\n.uploadButton {\n  display: flex;\n  justify-content: flex-end;\n} .button-edit{\n  flex: 1;\n  width: 3rem;\n  margin-right: 10px; /* Adjust as per your spacing preference */\n}\n\na[href=\"https://markerjs.com/\"] {\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
