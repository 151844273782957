import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jobDate } from "services";
import "./DaterangePicker.scss";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

const JobDates = ({ jobStartDate, jobEndDate, collectionName, jobId }) => {
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({ startDate: "", endDate: "" });

  useEffect(() => {
    if (jobStartDate) {
      const initialDate = getDate(jobStartDate);
      setStartDate(initialDate);
    }
    if (jobEndDate) {
      const endDate = getDate(jobEndDate);
      setEndDate(endDate);
    }
  }, [jobStartDate, jobEndDate]);

  const getDate = dateString => {
    return new Date(dateString);
  };

  const handleStartDateChange = async date => {
    setErrors({ startDate: "", endDate: "" });
    if (endDate >= date || !endDate) {
      setStartDate(date);
      if (endDate && endDate.getTime() === date.getTime()) {
        setEndDate(null);
      }
      if (endDate && endDate.getTime() === date.getTime()) {
        setErrors({ startDate: "", endDate: "" });
      }
      await updateFirestore(date, endDate);
    } else {
      setErrors({
        startDate: "Ensure start date is not less than the end date",
        endDate: errors.endDate,
      });
    }
  };

  const handleEndDateChange = async date => {
    setErrors({ startDate: "", endDate: "" });
    if (date >= startDate || !startDate) {
      setEndDate(date);
      if (startDate && startDate.getTime() === date.getTime()) {
        setStartDate(null);
      }
      if (startDate && startDate.getTime() === date.getTime()) {
        setErrors({ startDate: "", endDate: "" });
      }
      await updateFirestore(startDate, date);
    } else {
      setErrors({
        startDate: errors.startDate,
        endDate: "Ensure end date is greater than the start date",
      });
    }
  };

  const updateFirestore = async (newStartDate, newEndDate) => {
    try {
      await jobDate(
        jobId,
        {
          startDate: newStartDate,
          endDate: newEndDate,
        },
        collectionName
      );
      toast.success("Successfully added dates to job", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error updating dates. Please try again later.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <h5 className="font-size-15">Start Date</h5>
        <span
          className="mx-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DatePicker
            value={startDate || "Select start date"}
            selected={startDate}
            onChange={handleStartDateChange}
            startDate={startDate}
            className="custom-job-date-picker"
            dateFormat={"dd/MM/yyyy"}
            onSelect={e => {
              if (
                startDate &&
                e.getDate() === startDate.getDate() &&
                e.getMonth() === startDate.getMonth() &&
                e.getFullYear() === startDate.getFullYear()
              ) {
                setErrors({ startDate: "", endDate: "" });
              }
            }}
          />
          {errors.startDate && (
            <>
              <SweetAlert
                warning
                title="Start date cannot be greater than end date"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setErrors({ startDate: "", endDate: "" });
                }}
              ></SweetAlert>
            </>
          )}
        </span>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}
      >
        <h5 className="font-size-15">End Date</h5>
        <span
          className="mx-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "red",
            background: "transparent"
          }}
        >
          <DatePicker
            value={endDate || "Select end date"}
            selected={endDate}
            onChange={handleEndDateChange}
            endDate={endDate}
            onSelect={e => {
              if (
                endDate &&
                e.getDate() === endDate.getDate() &&
                e.getMonth() === endDate.getMonth() &&
                e.getFullYear() === endDate.getFullYear()
              ) {
                setErrors({ startDate: "", endDate: "" });
              }
            }}
            className="custom-job-date-picker"
            dateFormat={"dd/MM/yyyy"}
          />
          {errors.endDate && (
            <>
              <SweetAlert
                warning
                title="End date cannot be lesser than start date"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setErrors({ startDate: "", endDate: "" });
                }}
              ></SweetAlert>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default JobDates;
